import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G
        stroke="#737373"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round">
        <Path
          d="M5.332 1.333v2M10.668 1.333v2M2.332 6.06h11.333M14 5.667v5.666c0 2-1 3.334-3.333 3.334H5.333C3 14.667 2 13.333 2 11.333V5.667c0-2 1-3.334 3.333-3.334h5.334C13 2.333 14 3.667 14 5.667z"
          strokeMiterlimit={10}
        />
        <Path d="M10.463 9.133h.006M10.463 11.133h.006M7.998 9.133h.006M7.998 11.133h.006M5.529 9.133h.006M5.529 11.133h.006" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
