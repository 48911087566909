import * as React from "react";
import styles from "./styles";
import { Menu, Pressable, Text, Box, View } from "native-base";
import { ArrowDown } from "../../assets/images/svg";
import { useNavigate } from "react-router-dom";
import { logOutAction } from "../../networking/auth/authSlice";
import { useDispatch } from "react-redux";

export default function UserProfileButton({ name }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getInitials = (name) => {
    const words = name.split(" ");
    let initials = "";
    words.forEach((word, index) => {
      if (index === 0 || index === words.length - 1) {
        initials += word.charAt(0).toUpperCase();
      }
    });
    return initials;
  };
  const logOut = () => {
    dispatch(logOutAction());
    navigate("/");
  };
  const initials = getInitials(name || "");
  return (
    <Box alignItems="center">
      <Menu
        placement={"bottom right"}
        w="188"
        trigger={(triggerProps) => {
          return (
            <Pressable
              accessibilityLabel="More options menu"
              {...triggerProps}
              style={styles.userProfileButtonWrapper}
            >
              <View style={styles.userAvatarWrapper}>
                <Text style={styles.userAvatarText}>{initials}</Text>
              </View>
              <View>
                <ArrowDown />
              </View>
            </Pressable>
          );
        }}
      >
        <Text style={styles.menuName}>{name}</Text>
        <Menu.Item
          onPress={() => {
            navigate("/profile");
          }}
        >
          My profile
        </Menu.Item>
        <Menu.Item onPress={logOut}>Log out</Menu.Item>
      </Menu>
    </Box>
  );
}
