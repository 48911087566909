import * as React from "react";
import {
  Center,
  Button,
  Checkbox,
  FormControl,
  Input,
  Text,
  View,
  WarningOutlineIcon,
  Box,
} from "native-base";
import styles from "./styles";
import { Email, Eye, EyeSlash, Lock, Phone } from "../../assets/images/svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../networking/auth/authSlice";
import axios from "axios";
import { API_URL } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "../../config/constants";

export default function SignUpScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.pathname;

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const [hidden, setHidden] = useState(true);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(userInfo.email);
  const [password, setPassword] = useState("");
  const [checked, setChecked] = React.useState(false);

  // navigation.setOptions({
  //   name: "Sign Up",
  //   subheader: "Create account and enjoy all services",
  // });

  const showPassword = () => {
    setHidden(!hidden);
  };

  const goToSignIn = () => {
    if (type === "/signup/email") {
      navigate("/signin/email");
    } else {
      navigate("/signin/phone");
    }
  };

  const confirmCode = () => {
    dispatch(
      setUser({
        email,
        phone,
        password,
      })
    );
    if ((email || phone) && password && checked) {
      const verificationData = {};
      let sendType = "";
      if (type === "/signup/email") {
        verificationData.email = email;
        sendType = "email";
      }
      if (type === "/signup/phone") {
        verificationData.phone = phone;
        sendType = "phone";
      }
      axios.post(`${API_URL}/verification/${sendType}`, verificationData);
      if (type === "/signup/email" && email && password && checked) {
        navigate(`/confirm/email/${email}`);
      }
      if (type === "/signup/phone" && phone && password && checked) {
        navigate(`/confirm/phone/${phone}`);
      }
    }
  };

  return (
    <Box style={styles.wrapper}>
      <View style={isMobile() ? styles.containerMobile : styles.container}>
        <Center width={"282"}>
          <Text fontSize="2xl" bold>
            Sign Up
          </Text>
          <Text mt="5px" mb="10px" fontSize="md">
            Create account and enjoy all services
          </Text>
        </Center>
        <FormControl>
          {type === "/signup/email" && (
            <>
              <FormControl.Label>Email</FormControl.Label>
              <Input
                textContentType="emailAddress"
                keyboardType="email-address"
                autoCapitalize="none"
                type="email"
                autoCorrect={false}
                autoCompleteType="email"
                value={email}
                onChangeText={setEmail}
                InputLeftElement={
                  <View style={styles.leftIcon}>
                    <Email />
                  </View>
                }
                placeholder="Email Address"
              />
              <FormControl.ErrorMessage
                leftIcon={<WarningOutlineIcon size="xs" />}
              >
                Try different from previous passwords.
              </FormControl.ErrorMessage>
            </>
          )}
          {type === "/signup/phone" && (
            <>
              <FormControl.Label>Phone Number</FormControl.Label>
              <Input
                value={phone}
                onChangeText={setPhone}
                type="tel"
                InputLeftElement={
                  <View style={styles.leftIcon}>
                    <Phone />
                  </View>
                }
                placeholder="Enter your Phone Number"
              />
            </>
          )}
          <View style={styles.divider} />
          <FormControl.Label>Password</FormControl.Label>
          <Input
            type={hidden ? "password" : "text"}
            value={password}
            onChangeText={setPassword}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Lock />
              </View>
            }
            InputRightElement={
              <View onPress={showPassword} style={styles.rightIcon}>
                {hidden ? <EyeSlash /> : <Eye />}
              </View>
            }
            placeholder="Password"
          />
          <View style={styles.divider} />
          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                onChange={(state) => {
                  setChecked(state);
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer} fontSize="sm">
              I agree to the company{" "}
              <Text style={styles.actionText}>Term of Service</Text> and{" "}
              <Text style={styles.actionText}>Privacy Policy</Text>
            </Text>
          </View>
        </FormControl>
        <Button style={styles.button} onPress={confirmCode}>
          Continue
        </Button>
        <View style={styles.divider} />
        <View>
          <Text fontSize="sm">
            Already have an account?{" "}
            <Text style={styles.actionText} onPress={goToSignIn}>
              Sign In
            </Text>
          </Text>
        </View>
        <View style={styles.divider} />
      </View>
    </Box>
  );
}
