import * as React from 'react';
import Svg, {Rect, G, Path, Defs, ClipPath} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect width={48} height={48} rx={10} fill="#DAF8EA" />
      <G clipPath="url(#clip0_5225_23559)">
        <Path
          d="M27.281 22.36v-2.188a.547.547 0 01.547-.547h3.281a.548.548 0 01.49.302l1.093 2.188a.546.546 0 01-.489.791h-4.375a.547.547 0 01-.547-.547zm1.094-.547h2.943l-.547-1.094h-2.396v1.093zM27.828 24h1.64a.546.546 0 110 1.094h-1.64a.547.547 0 010-1.094z"
          fill="#1DCE80"
        />
        <Path
          d="M7.047 28.375V16.891a.547.547 0 01.547-.547H24a.547.547 0 010 1.093v7.11h1.094V18.53a1.095 1.095 0 011.093-1.093h1.094v1.093c0-.145.058-.284.16-.386.103-.103.339 1.37.387-.16a.547.547 0 01.547.546v-1.093h1.64v1.093a.547.547 0 111.094 0v-1.093h1.989c.145 0 .434.99.537 1.093-2.635-.656-.537-.426-.537-.281l.537.281-1.76-.656s1.573-.664 1.75 1.75a.547.547 0 01-.387-.16c-.102-.103-.615-2.357-.165-1.386l.562.452 1.672 3.345 4.155.832a1.858 1.858 0 011.491 1.818v3.849a.547.547 0 110 1.094h-2.79a2.735 2.735 0 01-5.358 0h-5.524c.306 0 .274 0 0 0h-7.164a2.725 2.725 0 01-4.867 1.077 2.725 2.725 0 01-4.867-1.077H7.047a.547.547 0 010-1.094zM24 25.641v1.093h1.094v-1.093H24zm11.484 4.922a1.64 1.64 0 100-3.281 1.64 1.64 0 000 3.28zm-2.679-2.188a2.734 2.734 0 015.358 0h1.696v-2.188h-.547a.547.547 0 010-1.093h.547v-.569a.762.762 0 00-.611-.745l-3.764-.753V24a.547.547 0 01-1.093 0v-1.511l-1.98-3.958h-6.224v8.203a1.095 1.095 0 011.094 1.094v.547h5.524zm-9.899 1.094h3.282v-.55l.001-1.09h-3.283v1.64zm0-2.735v-9.297H8.141v10.938h2.242a2.725 2.725 0 014.867-1.077 2.725 2.725 0 014.867 1.077h1.695v-.547a1.095 1.095 0 011.094-1.094zm-5.468 3.828a1.64 1.64 0 100-3.28 1.64 1.64 0 000 3.28z"
          fill="#1DCE80"
        />
        <Path
          d="M21.266 27.281a.547.547 0 01-.547-.547v-8.203a.547.547 0 111.093 0v8.203a.547.547 0 01-.546.547zM9.781 27.281a.547.547 0 01-.547-.547v-8.203a.547.547 0 111.094 0v8.203a.547.547 0 01-.547.547z"
          fill="#1DCE80"
        />
        <Path
          d="M8.001 17h15v9.923l-.295 1.643H7.813L8 17zM26.071 17.444h6.218L35 23l1.227-.138 1.273.388 2.5.25v5H27L26 27l.071-9.556z"
          fill="#1DCE80"
        />
        <Path
          d="M27.453 22.271v-2.047a.512.512 0 01.512-.511h3.07a.512.512 0 01.458.283l1.023 2.046a.513.513 0 01-.457.741h-4.094a.512.512 0 01-.512-.512zm1.024-.511h-.208l2.45-1.024-1.437 1.306-.805-.282zM12.977 31.281a2 2 0 100-4 2 2 0 000 4zM17.406 31.281a2 2 0 100-4 2 2 0 000 4zM35.531 31.281a2 2 0 100-4 2 2 0 000 4z"
          fill="#17A566"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5225_23559">
          <Path
            fill="#fff"
            transform="matrix(-1 0 0 1 41.5 6.5)"
            d="M0 0H35V35H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
