export const cars = [
  {
    brand: 'Alfa Romeo',
    models: [
      '145',
      '146',
      '147',
      '155',
      '156',
      '156 Sportwagon',
      '159',
      '159 Sportwagon',
      '164',
      '166',
      '4C',
      'Brera',
      'GTV',
      'MiTo',
      'Crosswagon',
      'Spider',
      'GT',
      'Giulietta',
      'Giulia',
    ],
  },
  {
    brand: 'Audi',
    models: [
      '100',
      '100 Avant',
      '80',
      '80 Avant',
      '80 Cabrio',
      '90',
      'A1',
      'A2',
      'A3',
      'A3 Cabriolet',
      'A3 Limuzina',
      'A3 Sportback',
      'A4',
      'A4 Allroad',
      'A4 Avant',
      'A4 Cabriolet',
      'A5',
      'A5 Cabriolet',
      'A5 Sportback',
      'A6',
      'A6 Allroad',
      'A6 Avant',
      'A7',
      'A8',
      'A8 Long',
      'Q3',
      'Q5',
      'Q7',
      'R8',
      'RS4 Cabriolet',
      'RS4/RS4 Avant',
      'RS5',
      'RS6 Avant',
      'RS7',
      'S3/S3 Sportback',
      'S4 Cabriolet',
      'S4/S4 Avant',
      'S5/S5 Cabriolet',
      'S6/RS6',
      'S7',
      'S8',
      'SQ5',
      'TT Coupé',
      'TT Roadster',
      'TTS',
    ],
  },
  {
    brand: 'BMW',
    models: [
      'i3',
      'i8',
      'M3',
      'M4',
      'M5',
      'M6',
      'Rad 1',
      'Rad 1 Cabrio',
      'Rad 1 Coupé',
      'Rad 2',
      'Rad 2 Active Tourer',
      'Rad 2 Coupé',
      'Rad 2 Gran Tourer',
      'Rad 3',
      'Rad 3 Cabrio',
      'Rad 3 Compact',
      'Rad 3 Coupé',
      'Rad 3 GT',
      'Rad 3 Touring',
      'Rad 4',
      'Rad 4 Cabrio',
      'Rad 4 Gran Coupé',
      'Rad 5',
      'Rad 5 GT',
      'Rad 5 Touring',
      'Rad 6',
      'Rad 6 Cabrio',
      'Rad 6 Coupé',
      'Rad 6 Gran Coupé',
      'Rad 7',
      'Rad 8 Coupé',
      'X1',
      'X3',
      'X4',
      'X5',
      'X6',
      'Z3',
      'Z3 Coupé',
      'Z3 Roadster',
      'Z4',
      'Z4 Roadster',
    ],
  },
  {
    brand: 'Chevrolet',
    models: [
      'Alero',
      'Aveo',
      'Camaro',
      'Captiva',
      'Corvette',
      'Cruze',
      'Cruze SW',
      'Epica',
      'Equinox',
      'Evanda',
      'HHR',
      'Kalos',
      'Lacetti',
      'Lacetti SW',
      'Lumina',
      'Malibu',
      'Matiz',
      'Monte Carlo',
      'Nubira',
      'Orlando',
      'Spark',
      'Suburban',
      'Tacuma',
      'Tahoe',
      'Trax',
    ],
  },
  {
    brand: 'Chrysler',
    models: [
      '300 C',
      '300 C Touring',
      '300 M',
      'Crossfire',
      'Grand Voyager',
      'LHS',
      'Neon',
      'Pacifica',
      'Plymouth',
      'PT Cruiser',
      'Sebring',
      'Sebring Convertible',
      'Stratus',
      'Stratus Cabrio',
      'Town & Country',
      'Voyager',
    ],
  },
  {
    brand: 'Citroën',
    models: [
      'Berlingo',
      'C-Crosser',
      'C-Elissée',
      'C-Zero',
      'C1',
      'C2',
      'C3',
      'C3 Picasso',
      'C4',
      'C4 Aircross',
      'C4 Cactus',
      'C4 Coupé',
      'C4 Grand Picasso',
      'C4 Sedan',
      'C5',
      'C5 Break',
      'C5 Tourer',
      'C6',
      'C8',
      'DS3',
      'DS4',
      'DS5',
      'Evasion',
      'Jumper',
      'Jumpy',
      'Saxo',
      'Nemo',
      'Xantia',
      'Xsara',
    ],
  },
  {
    brand: 'Dacia',
    models: [
      'Dokker',
      'Duster',
      'Lodgy',
      'Logan',
      'Logan MCV',
      'Logan Van',
      'Sandero',
      'Solenza',
    ],
  },
  {
    brand: 'Daewoo',
    models: [
      'Espero',
      'Kalos',
      'Lacetti',
      'Lanos',
      'Leganza',
      'Lublin',
      'Matiz',
      'Nexia',
      'Nubira',
      'Nubira kombi',
      'Racer',
      'Tacuma',
      'Tico',
    ],
  },
  {
    brand: 'Dodge',
    models: [
      'Avenger',
      'Caliber',
      'Challenger',
      'Charger',
      'Grand Caravan',
      'Journey',
      'Magnum',
      'Nitro',
      'RAM',
      'Stealth',
      'Viper',
    ],
  },
  {
    brand: 'Fiat',
    models: [
      '1100',
      '126',
      '500',
      '500L',
      '500X',
      '850',
      'Barchetta',
      'Brava',
      'Cinquecento',
      'Coupé',
      'Croma',
      'Doblo',
      'Doblo Cargo',
      'Doblo Cargo Combi',
      'Ducato',
      'Ducato Van',
      'Ducato Kombi',
      'Ducato Podvozok',
      'Florino',
      'Florino Combi',
      'Freemont',
      'Grande Punto',
      'Idea',
      'Linea',
      'Marea',
      'Marea Weekend',
      'Multipla',
      'Palio Weekend',
      'Panda',
      'Panda Van',
      'Punto',
      'Punto Cabriolet',
      'Punto Evo',
      'Punto Van',
      'Qubo',
      'Scudo',
      'Scudo Van',
      'Scudo Kombi',
      'Sedici',
      'Seicento',
      'Stilo',
      'Stilo Multiwagon',
      'Strada',
      'Talento',
      'Tipo',
      'Ulysse',
      'Uno',
      'X1/9',
    ],
  },
  {
    brand: 'Ford',
    models: [
      'Aerostar',
      'B-Max',
      'C-Max',
      'Cortina',
      'Cougar',
      'Edge',
      'Escort',
      'Escort Cabrio',
      'Escort kombi',
      'Explorer',
      'F-150',
      'F-250',
      'Fiesta',
      'Focus',
      'Focus C-Max',
      'Focus CC',
      'Focus kombi',
      'Fusion',
      'Galaxy',
      'Grand C-Max',
      'Ka',
      'Kuga',
      'Maverick',
      'Mondeo',
      'Mondeo Combi',
      'Mustang',
      'Orion',
      'Puma',
      'Ranger',
      'S-Max',
      'Sierra',
      'Street Ka',
      'Tourneo Connect',
      'Tourneo Custom',
      'Transit',
      'Transit',
      'Transit Bus',
      'Transit Connect LWB',
      'Transit Courier',
      'Transit Custom',
      'Transit kombi',
      'Transit Tourneo',
      'Transit Valnik',
      'Transit Van',
      'Transit Van 350',
      'Windstar',
    ],
  },
  {
    brand: 'Honda',
    models: [
      'Accord',
      'Accord Coupé',
      'Accord Tourer',
      'City',
      'Civic',
      'Civic Aerodeck',
      'Civic Coupé',
      'Civic Tourer',
      'Civic Type R',
      'CR-V',
      'CR-X',
      'CR-Z',
      'FR-V',
      'HR-V',
      'Insight',
      'Integra',
      'Jazz',
      'Legend',
      'Prelude',
    ],
  },
  {brand: 'Hummer', models: ['H2', 'H3']},
  {
    brand: 'Hyundai',
    models: [
      'Accent',
      'Atos',
      'Atos Prime',
      'Coupé',
      'Elantra',
      'Galloper',
      'Genesis',
      'Getz',
      'Grandeur',
      'H 350',
      'H1',
      'H1 Bus',
      'H1 Van',
      'H200',
      'i10',
      'i20',
      'i30',
      'i30 CW',
      'i40',
      'i40 CW',
      'ix20',
      'ix35',
      'ix55',
      'Lantra',
      'Matrix',
      'Santa Fe',
      'Sonata',
      'Terracan',
      'Trajet',
      'Tucson',
      'Veloster',
    ],
  },
  {
    brand: 'Infiniti',
    models: ['EX', 'FX', 'G', 'G Coupé', 'M', 'Q', 'QX'],
  },
  {
    brand: 'Jaguar',
    models: [
      'Daimler',
      'F-Pace',
      'F-Type',
      'S-Type',
      'Sovereign',
      'X-Type',
      'X-type Estate',
      'XE',
      'XF',
      'XJ',
      'XJ12',
      'XJ6',
      'XJ8',
      'XJ8',
      'XJR',
      'XK',
      'XK8 Convertible',
      'XKR',
      'XKR Convertible',
    ],
  },
  {
    brand: 'Jeep',
    models: [
      'Cherokee',
      'Commander',
      'Compass',
      'Grand Cherokee',
      'Patriot',
      'Renegade',
      'Wrangler',
    ],
  },
  {
    brand: 'Kia',
    models: [
      'Avella',
      'Besta',
      'Carens',
      'Carnival',
      'Cee`d',
      'Cee`d SW',
      'Cerato',
      'K 2500',
      'Magentis',
      'Opirus',
      'Optima',
      'Picanto',
      'Pregio',
      'Pride',
      'Pro Cee`d',
      'Rio',
      'Rio Combi',
      'Rio sedan',
      'Sephia',
      'Shuma',
      'Sorento',
      'Soul',
      'Sportage',
      'Venga',
    ],
  },
  {
    brand: 'Land Rover',
    models: [
      '109',
      'Defender',
      'Discovery',
      'Discovery Sport',
      'Freelander',
      'Range Rover',
      'Range Rover Evoque',
      'Range Rover Sport',
    ],
  },
  {
    brand: 'Lexus',
    models: [
      'CT',
      'GS',
      'GS 300',
      'GX',
      'IS',
      'IS 200',
      'IS 250 C',
      'IS-F',
      'LS',
      'LX',
      'NX',
      'RC F',
      'RX',
      'RX 300',
      'RX 400h',
      'RX 450h',
      'SC 430',
    ],
  },
  {
    brand: 'MINI',
    models: [
      'Cooper',
      'Cooper Cabrio',
      'Cooper Clubman',
      'Cooper D',
      'Cooper D Clubman',
      'Cooper S',
      'Cooper S Cabrio',
      'Cooper S Clubman',
      'Countryman',
      'Mini One',
      'One D',
    ],
  },
  {
    brand: 'Mazda',
    models: [
      '121',
      '2',
      '3',
      '323',
      '323 Combi',
      '323 Coupé',
      '323 F',
      '5',
      '6',
      '6 Combi',
      '626',
      '626 Combi',
      'B-Fighter',
      'B2500',
      'BT',
      'CX-3',
      'CX-5',
      'CX-7',
      'CX-9',
      'Demio',
      'MPV',
      'MX-3',
      'MX-5',
      'MX-6',
      'Premacy',
      'RX-7',
      'RX-8',
      'Xedox 6',
    ],
  },
  {
    brand: 'Mercedes-Benz',
    models: [
      '100 D',
      '115',
      '124',
      '126',
      '190',
      '190 D',
      '190 E',
      '200 - 300',
      '200 D',
      '200 E',
      '210 Van',
      '210 kombi',
      '310 Van',
      '310 kombi',
      '230 - 300 CE Coupé',
      '260 - 560 SE',
      '260 - 560 SEL',
      '500 - 600 SEC Coupé',
      'Trieda A',
      'A',
      'A L',
      'AMG GT',
      'Trieda B',
      'Trieda C',
      'C',
      'C Sportcoupé',
      'C T',
      'Citan',
      'CL',
      'CL',
      'CLA',
      'CLC',
      'CLK Cabrio',
      'CLK Coupé',
      'CLS',
      'Trieda E',
      'E',
      'E Cabrio',
      'E Coupé',
      'E T',
      'Trieda G',
      'G Cabrio',
      'GL',
      'GLA',
      'GLC',
      'GLE',
      'GLK',
      'Trieda M',
      'MB 100',
      'Trieda R',
      'Trieda S',
      'S',
      'S Coupé',
      'SL',
      'SLC',
      'SLK',
      'SLR',
      'Sprinter',
    ],
  },
  {
    brand: 'Mitsubishi',
    models: [
      '3000 GT',
      'ASX',
      'Carisma',
      'Colt',
      'Colt CC',
      'Eclipse',
      'Fuso canter',
      'Galant',
      'Galant Combi',
      'Grandis',
      'L200',
      'L200 Pick up',
      'L200 Pick up Allrad',
      'L300',
      'Lancer',
      'Lancer Combi',
      'Lancer Evo',
      'Lancer Sportback',
      'Outlander',
      'Pajero',
      'Pajeto Pinin',
      'Pajero Pinin Wagon',
      'Pajero Sport',
      'Pajero Wagon',
      'Space Star',
    ],
  },
  {
    brand: 'Nissan',
    models: [
      '100 NX',
      '200 SX',
      '350 Z',
      '350 Z Roadster',
      '370 Z',
      'Almera',
      'Almera Tino',
      'Cabstar E - T',
      'Cabstar TL2 Valnik',
      'e-NV200',
      'GT-R',
      'Insterstar',
      'Juke',
      'King Cab',
      'Leaf',
      'Maxima',
      'Maxima QX',
      'Micra',
      'Murano',
      'Navara',
      'Note',
      'NP300 Pickup',
      'NV200',
      'NV400',
      'Pathfinder',
      'Patrol',
      'Patrol GR',
      'Pickup',
      'Pixo',
      'Primastar',
      'Primastar Combi',
      'Primera',
      'Primera Combi',
      'Pulsar',
      'Qashqai',
      'Serena',
      'Sunny',
      'Terrano',
      'Tiida',
      'Trade',
      'Vanette Cargo',
      'X-Trail',
    ],
  },
  {
    brand: 'Opel',
    models: [
      'Agila',
      'Ampera',
      'Antara',
      'Astra',
      'Astra cabrio',
      'Astra caravan',
      'Astra coupé',
      'Calibra',
      'Campo',
      'Cascada',
      'Corsa',
      'Frontera',
      'Insignia',
      'Insignia kombi',
      'Kadett',
      'Meriva',
      'Mokka',
      'Movano',
      'Omega',
      'Signum',
      'Vectra',
      'Vectra Caravan',
      'Vivaro',
      'Vivaro Kombi',
      'Zafira',
    ],
  },
  {
    brand: 'Peugeot',
    models: [
      '1007',
      '107',
      '106',
      '108',
      '2008',
      '205',
      '205 Cabrio',
      '206',
      '206 CC',
      '206 SW',
      '207',
      '207 CC',
      '207 SW',
      '306',
      '307',
      '307 CC',
      '307 SW',
      '308',
      '308 CC',
      '308 SW',
      '309',
      '4007',
      '4008',
      '405',
      '406',
      '407',
      '407 SW',
      '5008',
      '508',
      '508 SW',
      '605',
      '806',
      '607',
      '807',
      'Bipper',
      'RCZ',
    ],
  },
  {
    brand: 'Porsche',
    models: [
      '911 Carrera',
      '911 Carrera Cabrio',
      '911 Targa',
      '911 Turbo',
      '924',
      '944',
      '997',
      'Boxster',
      'Cayenne',
      'Cayman',
      'Macan',
      'Panamera',
    ],
  },
  {
    brand: 'Renault',
    models: [
      'Captur',
      'Clio',
      'Clio Grandtour',
      'Espace',
      'Express',
      'Fluence',
      'Grand Espace',
      'Grand Modus',
      'Grand Scenic',
      'Kadjar',
      'Kangoo',
      'Kangoo Express',
      'Koleos',
      'Laguna',
      'Laguna Grandtour',
      'Latitude',
      'Mascott',
      'Mégane',
      'Mégane CC',
      'Mégane Combi',
      'Mégane Grandtour',
      'Mégane Coupé',
      'Mégane Scénic',
      'Scénic',
      'Talisman',
      'Talisman Grandtour',
      'Thalia',
      'Twingo',
      'Wind',
      'Zoé',
    ],
  },
  {
    brand: 'Rover',
    models: ['200', '214', '218', '25', '400', '414', '416', '620', '75'],
  },
  {
    brand: 'Saab',
    models: [
      '9-3',
      '9-3 Cabriolet',
      '9-3 Coupé',
      '9-3 SportCombi',
      '9-5',
      '9-5 SportCombi',
      '900',
      '900 C',
      '900 C Turbo',
      '9000',
    ],
  },
  {
    brand: 'Seat',
    models: [
      'Alhambra',
      'Altea',
      'Altea XL',
      'Arosa',
      'Cordoba',
      'Cordoba Vario',
      'Exeo',
      'Ibiza',
      'Ibiza ST',
      'Exeo ST',
      'Leon',
      'Leon ST',
      'Inca',
      'Mii',
      'Toledo',
    ],
  },
  {
    brand: 'Smart',
    models: [
      'Cabrio',
      'City-Coupé',
      'Compact Pulse',
      'Forfour',
      'Fortwo cabrio',
      'Fortwo coupé',
      'Roadster',
    ],
  },
  {
    brand: 'Subaru',
    models: [
      'BRZ',
      'Forester',
      'Impreza',
      'Impreza Wagon',
      'Justy',
      'Legacy',
      'Legacy Wagon',
      'Legacy Outback',
      'Levorg',
      'Outback',
      'SVX',
      'Tribeca',
      'Tribeca B9',
      'XV',
    ],
  },
  {
    brand: 'Suzuki',
    models: [
      'Alto',
      'Baleno',
      'Baleno kombi',
      'Grand Vitara',
      'Grand Vitara XL-7',
      'Ignis',
      'Jimny',
      'Kizashi',
      'Liana',
      'Samurai',
      'Splash',
      'Swift',
      'SX4',
      'SX4 Sedan',
      'Vitara',
      'Wagon R+',
    ],
  },
  {
    brand: 'Toyota',
    models: [
      '4-Runner',
      'Auris',
      'Avensis',
      'Avensis Combi',
      'Avensis Van Verso',
      'Aygo',
      'Camry',
      'Carina',
      'Celica',
      'Corolla',
      'Corolla Combi',
      'Corolla sedan',
      'Corolla Verso',
      'FJ Cruiser',
      'GT86',
      'Hiace',
      'Hiace Van',
      'Highlander',
      'Hilux',
      'Land Cruiser',
      'MR2',
      'Paseo',
      'Picnic',
      'Prius',
      'RAV4',
      'Sequoia',
      'Starlet',
      'Supra',
      'Tundra',
      'Urban Cruiser',
      'Verso',
      'Yaris',
      'Yaris Verso',
    ],
  },
  {
    brand: 'Volkswagen',
    models: [
      'Amarok',
      'Beetle',
      'Bora',
      'Bora Variant',
      'Caddy',
      'Caddy Van',
      'Life',
      'California',
      'Caravelle',
      'CC',
      'Crafter',
      'Crafter Van',
      'Crafter Kombi',
      'CrossTouran',
      'Eos',
      'Fox',
      'Golf',
      'Golf Cabrio',
      'Golf Plus',
      'Golf Sportvan',
      'Golf Variant',
      'Jetta',
      'LT',
      'Lupo',
      'Multivan',
      'New Beetle',
      'New Beetle Cabrio',
      'Passat',
      'Passat Alltrack',
      'Passat CC',
      'Passat Variant',
      'Passat Variant Van',
      'Phaeton',
      'Polo',
      'Polo Van',
      'Polo Variant',
      'Scirocco',
      'Sharan',
      'T4',
      'T4 Caravelle',
      'T4 Multivan',
      'T5',
      'T5 Caravelle',
      'T5 Multivan',
      'T5 Transporter Shuttle',
      'Tiguan',
      'Touareg',
      'Touran',
    ],
  },
  {
    brand: 'Volvo',
    models: [
      '240',
      '340',
      '360',
      '460',
      '850',
      '850 kombi',
      'C30',
      'C70',
      'C70 Cabrio',
      'C70 Coupé',
      'S40',
      'S60',
      'S70',
      'S80',
      'S90',
      'V40',
      'V50',
      'V60',
      'V70',
      'V90',
      'XC60',
      'XC70',
      'XC90',
    ],
  },
  {
    brand: 'Škoda',
    models: [
      'Favorit',
      'Felicia',
      'Citigo',
      'Fabia',
      'Fabia Combi',
      'Fabia Sedan',
      'Felicia Combi',
      'Octavia',
      'Octavia Combi',
      'Roomster',
      'Yeti',
      'Rapid',
      'Rapid Spaceback',
      'Superb',
      'Superb Combi',
    ],
  },
];
export const carsList = [
  'Acura',
  'Alfa Romeo',
  'Aston Martin',
  'Audi',
  'Bentley',
  'BMW',
  'Brilliance',
  'Bugatti',
  'Buick',
  'BYD',
  'Cadillac',
  'Changan',
  'Chery',
  'Chevrolet',
  'Chrysler',
  'Citroen',
  'Dacia',
  'Daewoo',
  'Daihatsu',
  'Datsun',
  'Dodge',
  'Dongfeng',
  'Exeed',
  'FAW',
  'Ferrari',
  'Fiat',
  'Fisker',
  'Ford',
  'Foton',
  'GAC',
  'GAZ',
  'Geely',
  'Genesis',
  'GMC',
  'Great Wall',
  'Haval',
  'Holden',
  'Honda',
  'Hummer',
  'Hyundai',
  'Infiniti',
  'Isuzu',
  'Iveco',
  'Jac',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lamborghini',
  'Lancia',
  'Land Rover',
  'Lexus',
  'Lifan',
  'Lincoln',
  'Lotus',
  'Marussia',
  'Maserati',
  'Maybach',
  'Mazda',
  'McLaren',
  'Mercedes',
  'Mercury',
  'MG',
  'Mini',
  'Mitsubishi',
  'Moskvich',
  'Nissan',
  'Opel',
  'Peugeot',
  'Plymouth',
  'Pontiac',
  'Porsche',
  'Ravon',
  'Renault',
  'Rolls-Royce',
  'Rover',
  'Saab',
  'Saturn',
  'Scion',
  'Seat',
  'Skoda',
  'Smart',
  'Ssang Yong',
  'Subaru',
  'Suzuki',
  'Tesla',
  'Toyota',
  'UAZ',
  'VAZ',
  'Volkswagen',
  'Volvo',
];
export const carsObj = {
  Acura: [
    'CDX',
    'CL',
    'EL',
    'ILX',
    'Integra',
    'MDX',
    'NSX',
    'RDX',
    'RL',
    'RLX',
    'RSX',
    'TL',
    'TLX',
    'TLX-L',
    'TSX',
    'ZDX',
  ],
  'Alfa Romeo': [
    '146',
    '147',
    '147 GTA',
    '156',
    '156 GTA',
    '159',
    '166',
    '4C',
    '8C Competizione',
    'Brera',
    'Giulia',
    'Giulietta',
    'GT',
    'GTV',
    'MiTo',
    'Spider',
    'Stelvio',
    'Tonale',
  ],
  'Aston Martin': [
    'Cygnet',
    'DB11',
    'DB9',
    'DBS',
    'DBS Superleggera',
    'DBS Violante',
    'DBX',
    'Rapide',
    'V12 Vanquish',
    'V12 Vantage',
    'V8 Vantage',
    'Valkyrie',
    'Vanquish',
    'Vantage',
    'Virage',
    'Zagato Coupe',
  ],
  Audi: [
    'A1',
    'A1 Allstreet',
    'A1 Citycarver',
    'A2',
    'A3',
    'A4',
    'A4 Allroad Quattro',
    'A5',
    'A6',
    'A7',
    'A8',
    'Allroad',
    'e-tron',
    'e-tron GT',
    'e-tron S',
    'e-tron S Sportback',
    'e-tron Sportback',
    'Q2',
    'Q2L',
    'Q3',
    'Q3 Sportback',
    'Q4',
    'Q4 Sportback',
    'Q5',
    'Q5 Sportback',
    'Q6',
    'Q7',
    'Q8',
    'Q8 e-tron',
    'R8',
    'RS e-tron GT',
    'RS Q3',
    'RS Q3 Sportback',
    'RS Q7',
    'RS Q8',
    'RS3',
    'RS4',
    'RS5',
    'RS6',
    'RS7',
    'S1',
    'S3',
    'S4',
    'S5',
    'S6',
    'S7',
    'S8',
    'SQ2',
    'SQ5',
    'SQ5 Sportback',
    'SQ7',
    'SQ8',
    'SQ8 e-tron',
    'SQ8 Sportback e-tron',
    'TT',
    'TT RS',
    'TTS',
  ],
  Bentley: [
    'Arnage',
    'Azure',
    'Bentayga',
    'Brooklands',
    'Continental',
    'Continental Flying Spur',
    'Continental GT',
    'Continental GTC',
    'Flying Spur',
    'Mulsanne',
  ],
  BMW: [
    '1 series',
    '2 series',
    '3 series',
    '4 series',
    '5 series',
    '6 series',
    '7 series',
    '8 series',
    'i3',
    'i4',
    'i7',
    'i8',
    'iX',
    'iX1',
    'iX3',
    'M2',
    'M3',
    'M4',
    'M5',
    'M6',
    'M8',
    'X1',
    'X2',
    'X3',
    'X3 M',
    'X4',
    'X4 M',
    'X5',
    'X5 M',
    'X6',
    'X6 M',
    'X7',
    'XM',
    'Z3',
    'Z4',
    'Z8',
  ],
  Brilliance: ['H230', 'V3', 'V5'],
  Bugatti: ['Chiron', 'Divo', 'Veyron'],
  Buick: [
    'Century',
    'Enclave',
    'Encore',
    'Envision',
    'GL8 ES',
    'La Crosse',
    'LaCrosse',
    'Le Sabre',
    'Lucerne',
    'Park Avenue',
    'Rainier',
    'Regal',
    'Rendezvouz',
    'Terraza',
    'Verano',
  ],
  BYD: ['Qin'],
  Cadillac: [
    'ATS',
    'ATS-V',
    'BLS',
    'CT4',
    'CT4-V',
    'CT5',
    'CT5-V',
    'CT6',
    'CTS',
    'De Ville',
    'DTS',
    'Eldorado',
    'ELR',
    'Escalade',
    'Lyriq',
    'Seville',
    'SRX',
    'STS',
    'XLR',
    'XT4',
    'XT5',
    'XT6',
    'XTS',
  ],
  Changan: [
    'Alsvin',
    'CS35',
    'CS35 Plus',
    'CS55',
    'CS55 Plus',
    'CS75',
    'CS75 Plus',
    'CS85 Coupe',
    'CS95',
    'Eado',
    'Eado Plus',
    'Raeton',
    'Raeton CC',
    'Raeton Plus',
    'Uni-K',
    'Uni-T',
    'Uni-V',
  ],
  Chery: [
    'Amulet',
    'Arrizo 5 Plus',
    'Arrizo 6',
    'Arrizo 6 Pro',
    'Arrizo 7',
    'Arrizo 8',
    'Bonus',
    'Bonus 3',
    'CrossEastar',
    'Eastar',
    'eQ',
    'eQ1',
    'eQ5',
    'Fora',
    'iCar',
    'IndiS',
    'Kimo',
    'M11',
    'Omoda 5',
    'QQ',
    'QQ3',
    'QQ6',
    'Tiggo',
    'Tiggo 2',
    'Tiggo 2 Pro',
    'Tiggo 3',
    'Tiggo 4',
    'Tiggo 4 Pro',
    'Tiggo 5',
    'Tiggo 7',
    'Tiggo 7 Pro',
    'Tiggo 8',
    'Tiggo 8 Plus',
    'Tiggo 8 Pro',
    'Tiggo 8 Pro Max',
    'Tiggo e',
    'Very',
  ],
  Chevrolet: [
    'Astro',
    'Avalanche',
    'Aveo',
    'Beat',
    'Blazer',
    'Bolt',
    'Bolt EUV',
    'Camaro',
    'Captiva',
    'Cavalier',
    'Cobalt',
    'Colorado',
    'Corvette',
    'Cruze',
    'Epica',
    'Equinox',
    'Express',
    'HHR',
    'Impala',
    'Lacetti',
    'Lanos',
    'Malibu',
    'Monte Carlo',
    'Niva',
    'Orlando',
    'Rezzo',
    'Silverado',
    'Silverado 1500',
    'Silverado 2500 HD',
    'Spark',
    'SSR',
    'Suburban',
    'Tahoe',
    'TrailBlazer',
    'Traverse',
    'Trax',
    'Uplander',
    'Venture',
  ],
  Chrysler: [
    '200',
    '300',
    '300M',
    'Aspen',
    'Concorde',
    'Crossfire',
    'Grand Caravan',
    'Grand Voyager',
    'Pacifica',
    'PT Cruiser',
    'Sebring',
    'Town &amp;amp; Country',
    'Voyager',
  ],
  Citroen: [
    'Berlingo',
    'C-Crosser',
    'C-Elysee',
    'C1',
    'C2',
    'C3',
    'C3 Aircross',
    'C3 Picasso',
    'C3 Pluriel',
    'C4',
    'C4 Aircross',
    'C4 Cactus',
    'C4 Picasso',
    'C4 SpaceTourer',
    'C4 X',
    'C5',
    'C5 Aircross',
    'C6',
    'C8',
    'DS 7 Crossback',
    'DS3',
    'DS4',
    'DS5',
    'Grand C4 Picasso',
    'Grand C4 SpaceTourer',
    'Jumper',
    'Jumpy',
    'Nemo',
    'Saxo',
    'Spacetourer',
    'Xsara',
    'Xsara Picasso',
  ],
  Dacia: ['Dokker', 'Lodgy', 'Solenza', 'Spring', 'SupeRNova'],
  Daewoo: ['Evanda', 'Kalos', 'Leganza', 'Magnus', 'Matiz', 'Nexia', 'Nubira'],
  Daihatsu: [
    'Applause',
    'Cast',
    'Copen',
    'Cuore',
    'Gran Move',
    'Luxio',
    'Materia',
    'Mebius',
    'Move',
    'Rocky',
    'Sirion',
    'Terios',
    'Trevis',
    'YRV',
  ],
  Datsun: ['mi-DO', 'on-DO'],
  Dodge: [
    'Avenger',
    'Caliber',
    'Caliber SRT4',
    'Caravan',
    'Challenger',
    'Charger',
    'Dakota',
    'Dart',
    'Durango',
    'Hornet',
    'Intrepid',
    'Journey',
    'Magnum',
    'Neon',
    'Nitro',
    'Ram 1500',
    'Ram 2500',
    'Ram 3500',
    'Ram SRT10',
    'Stratus',
    'Viper',
  ],
  Dongfeng: ['580', 'A30', 'Aeolus AX7', 'AX7', 'H30 Cross'],
  Exeed: ['TXL', 'VX'],
  FAW: [
    'Bestune T77',
    'Bestune T99',
    'Besturn B30',
    'Besturn B50',
    'Besturn B70',
    'Besturn X40',
    'Besturn X80',
    'Oley',
    'Vita',
  ],
  Ferrari: [
    '296',
    '348',
    '360',
    '456',
    '458',
    '488',
    '512',
    '550',
    '575 M',
    '599 GTB Fiorano',
    '599 GTO',
    '612',
    '812',
    'California',
    'California T',
    'Challenge Stradale',
    'Enzo',
    'F12',
    'F355',
    'F430',
    'F50',
    'F512 M',
    'F8 Spider',
    'F8 Tributo',
    'FF',
    'GTC4 Lusso',
    'LaFerrari',
    'Portofino',
    'Portofino M',
    'Roma',
    'SF90 Spider',
    'SF90 Stradale',
  ],
  Fiat: [
    '124 Spider',
    '500',
    '500L',
    '500X',
    'Albea',
    'Argo',
    'Brava',
    'Bravo',
    'Coupe',
    'Croma',
    'Doblo',
    'Ducato',
    'E-Doblo',
    'E-Ulysse',
    'Egea',
    'Freemont',
    'Grande Punto',
    'Idea',
    'Linea',
    'Marea',
    'Multipla',
    'Palio',
    'Panda',
    'Panda 4x4',
    'Panda Cross',
    'Pulse',
    'Punto',
    'Qubo',
    'Sedici',
    'Siena',
    'Stilo',
    'Strada',
    'Tipo',
    'Ulysse',
  ],
  Fisker: ['Karma'],
  Ford: [
    'B-Max',
    'Bronco',
    'Bronco Sport',
    'C-Max',
    'Cougar',
    'Crown Victoria',
    'EcoSport',
    'Edge',
    'Endura',
    'Equator',
    'Escape',
    'Excursion',
    'Expedition',
    'Explorer',
    'Explorer Sport Trac',
    'F-150',
    'F-150 Lightning',
    'F-250',
    'F-350',
    'Falcon',
    'Fiesta',
    'Fiesta Active',
    'Five Hundred',
    'Flex',
    'Focus',
    'Focus Active',
    'Focus Electric',
    'Freestar',
    'Freestyle',
    'Fusion',
    'Galaxy',
    'Ka',
    'Kuga',
    'Maverick',
    'Mondeo',
    'Mustang',
    'Mustang Mach-E',
    'Mustang Shelby GT350',
    'Mustang Shelby GT500',
    'Puma',
    'Ranger',
    'S-Max',
    'Taurus',
    'Taurus X',
    'Thunderbird',
    'Tourneo Connect',
    'Transit',
    'Transit Connect',
  ],
  Foton: ['Sauvana'],
  GAC: ['GM8', 'GS5', 'GS8'],
  GAZ: [
    '3102',
    '31105',
    'Gazelle',
    'Gazelle Business',
    'Gazelle Next',
    'Gazelle NN',
    'Gazelle Sity',
    'Siber',
    'Sobol',
  ],
  Geely: [
    'Atlas',
    'Atlas Pro',
    'Azkarra',
    'Coolray',
    'Emgrand 7',
    'Emgrand EC7',
    'Emgrand GS',
    'Emgrand GSe',
    'Emgrand X7',
    'GC9',
    'GС6',
    'MK',
    'Monjaro',
    'Otaka',
    'Preface',
    'Tugella',
    'Vision',
  ],
  Genesis: ['G70', 'G80', 'G90', 'GV60', 'GV70', 'GV80'],
  GMC: [
    'Acadia',
    'Canyon',
    'Envoy',
    'Sierra 1500',
    'Sierra 2500',
    'Sierra 3500',
    'Terrain',
    'Yukon',
  ],
  'Great Wall': [
    'Cowry',
    'Deer',
    'Hover',
    'Hover M2',
    'Pegasus',
    'Peri',
    'Poer',
    'Poer King Kong',
    'Safe',
    'Sailor',
    'Sing',
    'Socool',
    'Wingle',
    'Wingle 7',
  ],
  Haval: [
    'Cool Dog',
    'Dargo',
    'F7',
    'F7x',
    'H4',
    'H6',
    'H9',
    'Jolion',
    'M6 Plus',
  ],
  Holden: ['Commodore', 'Corvette C8'],
  Honda: [
    'Accord',
    'Amaze',
    'City',
    'Civic',
    'Civic Type R',
    'CR-V',
    'CR-Z',
    'Crosstour',
    'e',
    'Edix',
    'Element',
    'Fit',
    'FR-V',
    'HR-V',
    'Insight',
    'Jade',
    'Jazz',
    'Legend',
    'Odyssey',
    'Passport',
    'Pilot',
    'Prelude',
    'Ridgeline',
    'S2000',
    'Shuttle',
    'Stepwgn',
    'Stream',
    'Vezel',
  ],
  Hummer: ['H1', 'H2', 'H3'],
  Hyundai: [
    'Accent',
    'Atos Prime',
    'Azera',
    'Bayon',
    'Centennial',
    'Creta',
    'Creta Grand',
    'Elantra',
    'Entourage',
    'Eon',
    'Equus',
    'Galloper',
    'Genesis',
    'Genesis Coupe',
    'Getz',
    'Grandeur',
    'H-1',
    'i10',
    'i20',
    'i20 N',
    'i30',
    'i30 N',
    'i40',
    'Ioniq',
    'Ioniq 5',
    'Ioniq 6',
    'ix20',
    'ix35',
    'Kona',
    'Kona N',
    'Kusto',
    'Matrix',
    'Mistra',
    'Nexo',
    'Palisade',
    'Porter',
    'Santa Cruz',
    'Santa Fe',
    'Solaris',
    'Sonata',
    'Staria',
    'Terracan',
    'Trajet',
    'Tucson',
    'Veloster',
    'Venue',
    'Veracruz',
    'Verna',
    'Xcent',
    'XG',
  ],
  Infiniti: [
    'EX',
    'FX',
    'G',
    'I35',
    'JX',
    'M',
    'Q30',
    'Q40',
    'Q45',
    'Q50',
    'Q60',
    'Q70',
    'QX30',
    'QX4',
    'QX50',
    'QX55',
    'QX56',
    'QX60',
    'QX70',
    'QX80',
  ],
  Isuzu: [
    'Ascender',
    'Axiom',
    'D-Max',
    'D-Max Rodeo',
    'I280',
    'I290',
    'I350',
    'I370',
    'mu-X',
    'Rodeo',
    'Trooper',
    'VehiCross',
  ],
  Iveco: ['Daily'],
  Jac: ['iEV7S', 'J7', 'JS3', 'JS4', 'JS6', 'T6'],
  Jaguar: [
    'E-Pace',
    'F-Pace',
    'F-Type',
    'I-Pace',
    'S-Type',
    'X-Type',
    'XE',
    'XF',
    'XJ',
    'XK/XKR',
  ],
  Jeep: [
    'Cherokee',
    'Commander',
    'Compass',
    'Gladiator',
    'Grand Cherokee',
    'Grand Wagoneer',
    'Liberty',
    'Meridian',
    'Patriot',
    'Renegade',
    'Wagoneer',
    'Wrangler',
  ],
  Kia: [
    'Carens',
    'Carnival',
    'Ceed',
    'Cerato',
    'Clarus',
    'EV6',
    'Forte',
    'K5',
    'K8',
    'K900',
    'Magentis',
    'Mohave',
    'Niro',
    'Opirus',
    'Optima',
    'Picanto',
    'ProCeed',
    'Quoris',
    'Ray',
    'Rio',
    'Rio X',
    'Rio X-Line',
    'Seltos',
    'Shuma',
    'Sonet',
    'Sorento',
    'Sorento Prime',
    'Soul',
    'Spectra',
    'Sportage',
    'Stinger',
    'Stonic',
    'Telluride',
    'Venga',
    'XCeed',
  ],
  Lamborghini: [
    'Aventador',
    'Centenario',
    'Diablo',
    'Gallardo',
    'Huracan',
    'Murcielago',
    'Reventon',
    'Sian',
    'Urus',
  ],
  Lancia: ['Delta', 'Lybra', 'Musa', 'Phedra', 'Thema', 'Thesis', 'Ypsilon'],
  'Land Rover': [
    'Defender',
    'Discovery',
    'Discovery Sport',
    'Evoque',
    'Freelander',
    'Range Rover',
    'Range Rover Sport',
    'Range Rover Velar',
  ],
  Lexus: [
    'CT',
    'ES',
    'GS',
    'GX',
    'HS',
    'IS',
    'LC',
    'LFA',
    'LM',
    'LS',
    'LX',
    'NX',
    'RC',
    'RC F',
    'RX',
    'SC',
    'UX',
  ],
  Lifan: [
    'Breez',
    'Cebrium',
    'Celliya',
    'Murman',
    'Myway',
    'Smily',
    'Solano',
    'X50',
    'X60',
    'X70',
    'X80',
  ],
  Lincoln: [
    'Aviator',
    'Corsair',
    'Mark LT',
    'MKC',
    'MKS',
    'MKT',
    'MKX',
    'MKZ',
    'Nautilus',
    'Navigator',
    'Town Car',
    'Zephyr',
  ],
  Lotus: ['Elise', 'Emira', 'Europa S', 'Evora', 'Exige'],
  Marussia: ['B1', 'B2'],
  Maserati: [
    '3200 GT',
    'Ghibli',
    'Gran Cabrio',
    'Gran Turismo',
    'Gran Turismo S',
    'Grecale',
    'Levante',
    'MC20',
    'Quattroporte',
    'Quattroporte S',
  ],
  Maybach: ['57', '57 S', '62', '62 S', 'Landaulet'],
  Mazda: [
    '2',
    '2 Hybrid',
    '3',
    '323',
    '5',
    '6',
    '626',
    'B-Series',
    'BT-50',
    'CX-3',
    'CX-30',
    'CX-30 EV',
    'CX-4',
    'CX-5',
    'CX-50',
    'CX-60',
    'CX-7',
    'CX-8',
    'CX-9',
    'MPV',
    'MX-30',
    'MX-5',
    'Premacy',
    'RX-7',
    'RX-8',
    'Tribute',
  ],
  McLaren: [
    '540C',
    '570S',
    '600LT',
    '650S',
    '675LT',
    '720S',
    '720S Spider',
    '765LT',
    'Artura',
    'MP4-12C',
    'P1',
  ],
  Mercedes: [
    'A-class',
    'AMG GT',
    'AMG GT 4-Door',
    'B-class',
    'C-class',
    'C-class Sport Coupe',
    'Citan',
    'CL-class',
    'CLA-class',
    'CLC-class',
    'CLK-class',
    'CLS-class',
    'E-class',
    'E-class Coupe',
    'EQA',
    'EQB',
    'EQC',
    'EQE',
    'EQE AMG',
    'EQS',
    'EQS AMG',
    'EQT',
    'EQV',
    'G-class',
    'GL-class',
    'GLA-class',
    'GLA-class AMG',
    'GLB-class',
    'GLC-class',
    'GLC-class AMG',
    'GLC-class Coupe',
    'GLE-class',
    'GLE-class AMG',
    'GLE-class Coupe',
    'GLK-class',
    'GLS-class',
    'GLS-class AMG',
    'M-class',
    'R-class',
    'S-class',
    'S-class Cabrio',
    'S-class Coupe',
    'SL-class',
    'SL-Class AMG',
    'SLC-class',
    'SLK-class',
    'SLR-class',
    'SLS AMG',
    'Sprinter',
    'Vaneo',
    'Viano',
    'Vito',
    'X-class',
  ],
  Mercury: [
    'Grand Marquis',
    'Mariner',
    'Milan',
    'Montego',
    'Monterey',
    'Mountaineer',
    'Sable',
  ],
  MG: [
    '4',
    'Hector',
    'TF',
    'VS',
    'XPower SV',
    'ZR',
    'ZS',
    'ZS EV',
    'ZT',
    'ZT-T',
  ],
  Mini: [
    'Clubman',
    'Clubman S',
    'Clubvan',
    'Cooper',
    'Cooper Cabrio',
    'Cooper S',
    'Cooper S Cabrio',
    'Cooper S Countryman All4',
    'Countryman',
    'One',
  ],
  Mitsubishi: [
    '3000 GT',
    'ASX',
    'Carisma',
    'Colt',
    'Dignity',
    'Eclipse',
    'Eclipse Cross',
    'Endeavor',
    'Galant',
    'Grandis',
    'i-MiEV',
    'L200',
    'Lancer',
    'Lancer Evo',
    'Mirage',
    'Outlander',
    'Outlander Sport',
    'Outlander XL',
    'Pajero',
    'Pajero Pinin',
    'Pajero Sport',
    'Raider',
    'Space Gear',
    'Space Runner',
    'Space Star',
    'Xpander',
  ],
  Moskvich: ['3e'],
  Nissan: [
    '350Z',
    '370Z',
    'Almera',
    'Almera Classic',
    'Almera Tino',
    'Altima',
    'Ariya',
    'Armada',
    'Bluebird Sylphy',
    'Frontier',
    'GT-R',
    'Juke',
    'Leaf',
    'Maxima',
    'Micra',
    'Murano',
    'Navara',
    'Note',
    'NP300',
    'Pathfinder',
    'Patrol',
    'Primera',
    'Qashqai',
    'Qashqai+2',
    'Quest',
    'Rogue',
    'Sentra',
    'Skyline',
    'Sylphy',
    'Teana',
    'Terrano',
    'Tiida',
    'Titan',
    'Titan XD',
    'X-Trail',
    'XTerra',
    'Z',
  ],
  Opel: [
    'Adam',
    'Agila',
    'Ampera-e',
    'Antara',
    'Astra',
    'Astra GTC',
    'Astra OPC',
    'Cascada',
    'Combo',
    'Combo Life',
    'Corsa',
    'Corsa OPC',
    'Crossland',
    'Crossland X',
    'Frontera',
    'Grandland',
    'Grandland X',
    'Insignia',
    'Insignia OPC',
    'Karl',
    'Meriva',
    'Mokka',
    'Omega',
    'Signum',
    'Speedster',
    'Tigra',
    'Vectra',
    'Vivaro',
    'Zafira',
    'Zafira Life',
    'Zafira Tourer',
  ],
  Peugeot: [
    '1007',
    '107',
    '108',
    '2008',
    '206',
    '207',
    '208',
    '3008',
    '301',
    '307',
    '308',
    '4007',
    '4008',
    '406',
    '407',
    '408',
    '5008',
    '508',
    '607',
    '807',
    'Boxer',
    'Expert',
    'Landtrek',
    'Manager',
    'Partner',
    'Partner Crossway',
    'Partner Rapid',
    'RCZ Sport',
    'Rifter',
    'Traveller',
  ],
  Plymouth: ['Road Runner'],
  Pontiac: [
    'Aztec',
    'Bonneville',
    'Firebird',
    'G5 Pursuit',
    'G6',
    'G8',
    'Grand AM',
    'Grand Prix',
    'GTO',
    'Montana',
    'Solstice',
    'Sunfire',
    'Torrent',
    'Vibe',
  ],
  Porsche: [
    '718 Boxster',
    '718 Cayman',
    '911',
    'Boxster',
    'Cayenne',
    'Cayman',
    'Macan',
    'Panamera',
    'Taycan',
  ],
  Ravon: ['Gentra'],
  Renault: [
    'Alaskan',
    'Arkana',
    'Austral',
    'Avantime',
    'Captur',
    'Clio',
    'Duster',
    'Duster Oroch',
    'Espace',
    'Fluence',
    'Grand Scenic',
    'Kadjar',
    'Kangoo',
    'Kaptur',
    'Kiger',
    'Koleos',
    'Laguna',
    'Latitude',
    'Logan',
    'Logan Stepway',
    'Master',
    'Megane',
    'Megane E-Tech',
    'Modus',
    'Sandero',
    'Sandero Stepway',
    'Scenic',
    'Symbol',
    'Taliant',
    'Talisman',
    'Trafic',
    'Triber',
    'Twingo',
    'Twizy',
    'Vel Satis',
    'Wind',
    'Zoe',
  ],
  'Rolls-Royce': ['Cullinan', 'Dawn', 'Ghost', 'Phantom', 'Wraith'],
  Rover: ['25', '400', '45', '600', '75', 'Streetwise'],
  Saab: ['9-2x', '9-3', '9-4x', '9-5', '9-7x'],
  Saturn: ['Aura', 'Ion', 'LW', 'Outlook', 'Sky', 'Vue'],
  Scion: ['FR-S', 'tC', 'xA', 'xB', 'xD'],
  Seat: [
    'Alhambra',
    'Altea',
    'Altea Freetrack',
    'Altea XL',
    'Arona',
    'Arosa',
    'Ateca',
    'Cordoba',
    'Exeo',
    'Ibiza',
    'Leon',
    'Mii',
    'Tarraco',
    'Toledo',
  ],
  Skoda: [
    'Citigo',
    'Enyaq iV',
    'Fabia',
    'Felicia',
    'Kamiq',
    'Karoq',
    'Kodiaq',
    'Octavia',
    'Octavia Scout',
    'Octavia Tour',
    'Praktik',
    'Rapid',
    'Rapid Spaceback (NH1)',
    'Roomster',
    'Scala',
    'Superb',
    'Yeti',
  ],
  Smart: ['#1', 'Forfour', 'Fortwo', 'Roadster'],
  'Ssang Yong': [
    'Actyon',
    'Actyon Sports',
    'Chairman',
    'Korando',
    'Kyron',
    'LUVi',
    'Musso',
    'Musso Grand',
    'Musso Sport',
    'Rexton',
    'Rexton Sports',
    'Rodius',
    'Stavic',
    'Tivoli',
    'Tivoli Grand',
    'XLV',
  ],
  Subaru: [
    'Ascent',
    'Baja',
    'BRZ',
    'Crosstrack',
    'Exiga',
    'Forester',
    'Impreza',
    'Impreza WRX',
    'Impreza WRX STI',
    'Justy',
    'Legacy',
    'Levorg',
    'Outback',
    'Solterra',
    'Traviq',
    'Tribeca',
    'WRX',
    'XV',
  ],
  Suzuki: [
    'Alto',
    'Baleno',
    'Celerio',
    'Ciaz',
    'Ertiga',
    'Grand Vitara',
    'Grand Vitara XL7',
    'Ignis',
    'Jimny',
    'Kizashi',
    'Liana',
    'S-Presso',
    'Splash',
    'Swift',
    'SX4',
    'Vitara',
    'Wagon R',
    'Wagon R+',
    'XL6',
    'XL7',
  ],
  Tesla: ['Model 3', 'Model S', 'Model X', 'Model Y'],
  Toyota: [
    '4Runner',
    'Alphard',
    'Auris',
    'Avalon',
    'Avensis',
    'Avensis Verso',
    'Aygo',
    'Aygo X',
    'BZ4X',
    'C+pod',
    'C-HR',
    'Caldina',
    'Camry',
    'Celica',
    'Corolla',
    'Corolla Cross',
    'Corolla Verso',
    'Crown',
    'FJ Cruiser',
    'Fortuner',
    'GT 86',
    'Harrier',
    'Hiace',
    'Highlander',
    'Hilux',
    'iQ',
    'ist',
    'Land Cruiser',
    'Land Cruiser Prado',
    'Mark II',
    'Mirai',
    'MR2',
    'Picnic',
    'Previa',
    'Prius',
    'Prius Prime',
    'RAV4',
    'Sequoia',
    'Sienna',
    'Supra',
    'Tacoma',
    'Tundra',
    'Venza',
    'Verso',
    'Vitz',
    'Wish',
    'Yaris',
    'Yaris Verso',
  ],
  UAZ: [
    '3153 Gusar',
    '3159 Bars',
    '3160',
    '3162 Simbir',
    'Buhanka',
    'Patriot Sport',
    'Pickup',
    'Profi',
    'Патриот',
    'Хантер',
  ],
  VAZ: [
    '2101-2107',
    '2108, 2109, 21099',
    '2110, 2111, 2112',
    '2113, 2114, 2115',
    '4x4 Urban',
    'Granta',
    'Granta Cross',
    'Largus',
    'Largus Cross',
    'Niva Bronto',
    'Niva Legend',
    'Niva Travel',
    'Vesta Cross',
    'Vesta Sport',
    'Vesta SW',
    'XRay',
    'XRay Cross',
    'Веста',
    'Калина',
    'Нива 4X4',
    'Ока',
    'Приора',
  ],
  Volkswagen: [
    'Amarok',
    'Arteon',
    'Beetle',
    'Bora',
    'Caddy',
    'CC',
    'Crafter',
    'CrossGolf',
    'CrossPolo',
    'CrossTouran',
    'Eos',
    'Fox',
    'Golf',
    'ID.3',
    'ID.4',
    'ID.4 X',
    'ID.5',
    'ID.6 Crozz',
    'ID.6 X',
    'ID.Buzz',
    'Jetta',
    'Lupo',
    'Multivan',
    'New Beetle',
    'Passat',
    'Passat CC',
    'Phaeton',
    'Pointer',
    'Polo',
    'Routan',
    'Scirocco',
    'Sharan',
    'T-Cross',
    'T-Roc',
    'Taigo',
    'Taos',
    'Tayron',
    'Teramont',
    'Teramont X',
    'Tiguan',
    'Tiguan X',
    'Touareg',
    'Touran',
    'Transporter',
    'Up',
  ],
  Volvo: [
    'C30',
    'C40',
    'C70',
    'C70 Convertible',
    'C70 Coupe',
    'EX30',
    'EX90',
    'S40',
    'S60',
    'S70',
    'S80',
    'S90',
    'V40',
    'V50',
    'V60',
    'V70',
    'V90',
    'XC40',
    'XC60',
    'XC70',
    'XC90',
  ],
};
