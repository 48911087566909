import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={112}
      height={40}
      viewBox="0 0 112 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M50.329 26.898c-.277 0-.55-.11-.758-.319l-9.157-9.16a1.194 1.194 0 01-.344-.836c-.002-.317.12-.62.334-.84l9.158-9.376a1.06 1.06 0 011.196-.236c.408.182.673.603.673 1.07v4.463c0 .34.262.617.584.617h7.31c1.536 0 2.787 1.32 2.787 2.941v1.729l6.464-6.619-6.464-6.466v1.556c0 1.621-1.25 2.94-2.788 2.94h-6.16V6.039h6.16c.323 0 .585-.276.585-.616v-4.26c0-.464.262-.884.666-1.068a1.059 1.059 0 011.193.225l9.157 9.16c.218.217.342.52.344.836.001.317-.12.62-.335.84l-9.156 9.376a1.06 1.06 0 01-1.196.236 1.166 1.166 0 01-.672-1.07v-4.475c0-.34-.263-.617-.585-.617h-7.31c-1.536 0-2.787-1.32-2.787-2.941V9.948l-6.464 6.619 6.464 6.465v-1.556c0-1.622 1.251-2.941 2.788-2.941h5.95v2.324h-5.95c-.322 0-.585.277-.585.617v4.26c0 .463-.262.884-.666 1.067a1.05 1.05 0 01-.435.094l-.001.001z"
        fill="url(#paint0_linear_4502_13867)"
      />
      <Path
        d="M0 37.393h2.358c.132.708.638 1.003 1.432 1.003.734 0 1.07-.248 1.07-.72 0-.53-.372-.743-1.672-1.05-2.454-.578-2.935-1.45-2.935-2.548 0-1.356 1.022-2.5 3.32-2.5 2.407 0 3.381 1.19 3.501 2.477H4.728c-.06-.437-.288-.885-1.155-.885-.553 0-.902.212-.902.649 0 .436.313.613 1.588.92 2.55.625 3.056 1.522 3.056 2.678C7.315 38.938 6.112 40 3.682 40 1.396 40 .18 38.95 0 37.393zM7.956 39.847V31.73h2.406v3.291c.541-.731 1.769-2.218 2.587-3.291h2.875l-3.044 3.326 3.14 4.79h-2.839l-1.985-3.256-.734.743v2.513H7.956zM16.157 31.73h2.43v8.117h-2.43V31.73zM19.824 39.847V31.73h3.838c1.864 0 3.128 1.014 3.128 2.607 0 1.816-1.432 2.666-3.188 2.666h-1.384v2.843h-2.394zm2.394-6.453v1.935h.986c.686 0 1.18-.283 1.18-.968 0-.696-.494-.967-1.168-.967h-.998zM34.06 39.847h-2.43v-2.808a.517.517 0 00-.085-.306l-2.84-5.002h2.744c.53 1.144 1.13 2.465 1.504 3.385.337-.884 1.035-2.347 1.528-3.385h2.526l-2.863 4.99a.47.47 0 00-.084.306v2.82zM40.748 31.577c2.61 0 4.127 1.782 4.127 4.212 0 2.288-1.42 4.211-4.26 4.211-2.779 0-4.126-1.91-4.126-4.211 0-2.336 1.48-4.212 4.259-4.212zm-.084 1.71c-1.18 0-1.673.956-1.673 2.466 0 1.498.506 2.537 1.709 2.537 1.263 0 1.672-1.097 1.672-2.49 0-1.474-.433-2.512-1.708-2.512zM45.66 31.73h2.43v4.99c0 1.169.578 1.57 1.324 1.57.794 0 1.251-.484 1.251-1.57v-4.99h2.442v4.755c0 2.701-1.624 3.515-3.741 3.515-2.19 0-3.706-.861-3.706-3.504v-4.765zM57.476 36.78h-.854v3.067h-2.358V31.73h4.102c1.95 0 2.9 1.026 2.9 2.241 0 1.133-.674 1.699-1.252 1.935.53.177 1.083.72 1.083 2.1v.33c0 .507.012 1.168.109 1.51h-2.31c-.145-.342-.18-.932-.18-1.77v-.212c0-.696-.17-1.085-1.24-1.085zm-.854-3.398v1.746h1.119c.806 0 1.106-.33 1.106-.885 0-.542-.348-.86-1.094-.86h-1.131z"
        fill="#fff"
      />
      <Path
        d="M64.287 39.847V31.73H68.4c2.01 0 2.888.967 2.888 2.111 0 .861-.47 1.487-1.131 1.758.626.212 1.36.779 1.36 1.828 0 1.334-1.023 2.419-3.08 2.419h-4.151zm2.358-4.99h1.347c.674 0 .939-.33.939-.779 0-.495-.373-.743-.95-.743h-1.336v1.522zm0 1.604v1.793h1.24c.793 0 1.13-.342 1.13-.92 0-.507-.313-.873-1.095-.873h-1.275zM75.43 36.78h-.854v3.067h-2.358V31.73h4.103c1.949 0 2.9 1.026 2.9 2.241 0 1.133-.674 1.699-1.252 1.935.53.177 1.083.72 1.083 2.1v.33c0 .507.012 1.168.108 1.51h-2.31c-.144-.342-.18-.932-.18-1.77v-.212c0-.696-.169-1.085-1.24-1.085zm-.854-3.398v1.746h1.12c.805 0 1.106-.33 1.106-.885 0-.542-.349-.86-1.095-.86h-1.13zM83.977 31.577c2.61 0 4.126 1.782 4.126 4.212 0 2.288-1.42 4.211-4.259 4.211-2.779 0-4.126-1.91-4.126-4.211 0-2.336 1.48-4.212 4.259-4.212zm-.085 1.71c-1.179 0-1.672.956-1.672 2.466 0 1.498.505 2.537 1.709 2.537 1.263 0 1.672-1.097 1.672-2.49 0-1.474-.433-2.512-1.709-2.512zM88.962 39.847V31.73h2.406v3.291c.542-.731 1.769-2.218 2.587-3.291h2.875l-3.043 3.326 3.14 4.79h-2.84l-1.985-3.256-.734.743v2.513h-2.406zM103.275 34.798v1.77h-3.778v1.521h4.212l-.265 1.758h-6.28V31.73h6.268v1.757h-3.934v1.31h3.777zM107.543 36.78h-.854v3.067h-2.358V31.73h4.103c1.949 0 2.899 1.026 2.899 2.241 0 1.133-.673 1.699-1.251 1.935.529.177 1.083.72 1.083 2.1v.33c0 .507.012 1.168.108 1.51h-2.31c-.144-.342-.18-.932-.18-1.77v-.212c0-.696-.169-1.085-1.24-1.085zm-.854-3.398v1.746h1.119c.806 0 1.107-.33 1.107-.885 0-.542-.349-.86-1.095-.86h-1.131z"
        fill="#1DCE80"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_4502_13867"
          x1={52.4055}
          y1={29.3796}
          x2={60.882}
          y2={-7.79077}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#29826C" />
          <Stop offset={1} stopColor="#4DFFA9" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
