import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect width={32} height={32} rx={8} fill="#1DCE80" />
      <Path
        d="M16.106 15.247a1.206 1.206 0 00-.22 0 2.947 2.947 0 01-2.847-2.954c0-1.633 1.32-2.96 2.96-2.96a2.957 2.957 0 01.107 5.913zM12.773 17.707c-1.614 1.08-1.614 2.84 0 3.913 1.833 1.227 4.84 1.227 6.673 0 1.613-1.08 1.613-2.84 0-3.913-1.827-1.22-4.834-1.22-6.674 0z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
