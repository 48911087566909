import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  stepper: {
    alignItems: 'center',
  },
  divider: {
    height: 16,
  },
  emptySpace: {
    height: 50,
  },
  leftIcon: {
    marginLeft: 12,
  },
  row: {
    flexDirection: 'row',
    marginTop: 16,
    marginBottom: 16,
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
    color: '#525252',
    fontSize: 14,
  },
});

export default styles;
