import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G
        stroke="#1DCE80"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round">
        <Path d="M6.668 1.667v2.5M13.332 1.667v2.5M5.832 10.833h6.667M5.832 14.167h4.167M13.333 2.917c2.775.15 4.167 1.208 4.167 5.125v5.15c0 3.433-.833 5.15-5 5.15h-5c-4.167 0-5-1.717-5-5.15v-5.15c0-3.917 1.392-4.967 4.167-5.125h6.666z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
