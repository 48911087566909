export const formatDate = date => {
  if (typeof date === 'string') {
    return date;
  }
  return `${date?.getMonth() + 1}/${date?.getDate()}/${date?.getFullYear()}`;
};

export const formatStringToDate = dateString => {
  const date = new Date(dateString);

  return `${date?.getMonth() + 1 || '00'}/${date?.getDate() || '00'}/${
    date?.getFullYear() || '00'
  }`;
};
