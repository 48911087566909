import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G clipPath="url(#clip0_5200_23187)" fill="#737373">
        <Path d="M9.75 10.875v-1.5A.375.375 0 009.375 9h-2.25a.375.375 0 00-.335.207l-.75 1.5a.375.375 0 00.335.543h3a.375.375 0 00.375-.375zM9 10.5H6.982l.375-.75H9v.75zM9.375 12H8.25a.375.375 0 100 .75h1.125a.375.375 0 100-.75z" />
        <Path d="M23.625 15V7.125a.375.375 0 00-.375-.375H11.625a.375.375 0 000 .75H12v4.875h-.75V8.25a.75.75 0 00-.75-.75h-.75v-.375a.375.375 0 00-.75 0V7.5H7.875v-.375a.375.375 0 00-.75 0V7.5H5.761c-.1 0-.298.68-.368.75 1.807-.45.368-.292.368-.193l-.368.193L6.6 7.8S5.522 7.345 5.4 9c.1 0 .195-.04.265-.11s.422-1.616.114-.95l-.386.31-1.146 2.293-2.85.57A1.274 1.274 0 00.375 12.36V15a.375.375 0 100 .75h1.913a1.875 1.875 0 003.674 0H9.75a.75.75 0 00.75.75h2.25a.75.75 0 00.75-.75h1.163a1.87 1.87 0 003.337.738 1.869 1.869 0 003.337-.738h2.288a.375.375 0 000-.75zM12 13.125v.75h-.75v-.75H12zM4.125 16.5a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zM5.962 15a1.875 1.875 0 00-3.674 0H1.125v-1.5H1.5a.375.375 0 100-.75h-.375v-.39a.522.522 0 01.42-.51l2.58-.518V12a.375.375 0 00.75 0v-1.036L6.232 8.25H10.5v5.625a.75.75 0 00-.75.75V15H5.962zm6.788.75H10.5v-.377l-.001-.748h2.251v1.125zm0-1.875V7.5h10.125V15h-1.538A1.87 1.87 0 0018 14.262a1.869 1.869 0 00-3.337.738H13.5v-.375a.75.75 0 00-.75-.75zM16.5 16.5a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm3 0a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25z" />
        <Path d="M13.875 14.25a.375.375 0 00.375-.375V8.25a.375.375 0 10-.75 0v5.625a.375.375 0 00.375.375zM21.75 14.25a.375.375 0 00.375-.375V8.25a.375.375 0 10-.75 0v5.625a.375.375 0 00.375.375z" />
      </G>
      <Defs>
        <ClipPath id="clip0_5200_23187">
          <Path fill="#fff" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
