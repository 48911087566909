import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G stroke="#1DCE80" strokeWidth={1.5}>
        <Path d="M10 11.192a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2z" />
        <Path d="M3.017 7.075C4.658-.142 15.35-.133 16.983 7.083c.959 4.234-1.675 7.817-3.983 10.034a4.328 4.328 0 01-6.008 0c-2.3-2.217-4.934-5.809-3.975-10.042z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
