import * as React from "react";
import { Button, Text, useToast, View, Box, Pressable } from "native-base";
import styles from "./styles";
// import {
//   CodeField,
//   Cursor,
//   useBlurOnFulfill,
//   useClearByFocusCell,
// } from "react-native-confirmation-code-field";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../../config";
import { loginUser, loginUserByOtp } from "../../../networking/auth/authApi";
import { useDispatch } from "react-redux";
import { setToken } from "../../../networking/auth/authSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";

export default function ConfirmPasswordCodeScreen() {
  const { type, data } = useParams();
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const toast = useToast();
  const backToSignIn = () => {
    navigate("/signIn");
  };

  const goNext = () => {
    axios
      .post(`${API_URL}/auth/otp`, {
        to: type,
        code,
      })
      .then((res) => {
        // navigation.navigate("ChangePassword", { token: res.data.accessToken });
      })
      .catch((err) => {
        toast.show({
          description: "Incorrect code",
          placement: "top",
        });
        console.log("err", err);
      });
  };

  return (
    <Box style={styles.wrapper}>
      <View style={styles.container}>
        <Text fontSize="2xl" bold pb={3} textAlign={"center"}>
          Enter verification code
        </Text>
        <Text style={styles.subheader}>
          A OTP has been sent to <Text bold> {data}</Text>. Kind enter below the
          6 digit code.
        </Text>
        <OtpInput
          value={code}
          onChange={setCode}
          numInputs={6}
          inputType="tel"
          containerStyle={{ display: "flex", justifyContent: "space-between" }}
          inputStyle={styles.inputOpt}
          shouldAutoFocus={1}
          renderInput={(props) => <input {...props} />}
        />
        <View mt={6}>
          <Text fontSize="sm">
            Didn’t receive the OTP?{" "}
            <Text
              onPress={() => {
                axios.get(`${API_URL}/auth/otp/${data}`).then((el) => {});
                toast.show({
                  description: "Sent new code",
                  placement: "top",
                });
              }}
              style={styles.actionText}
            >
              Resend OTP
            </Text>
          </Text>
        </View>
        <View style={styles.buttonContainer}>
          <Button style={styles.button} onPress={goNext}>
            Continue
          </Button>
        </View>
        <Pressable
          textAlign={"center"}
          style={styles.actionText}
          onPress={backToSignIn}
        >
          Back to Sign In
        </Pressable>
      </View>
    </Box>
  );
}
