import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G
        stroke="#1DCE80"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round">
        <Path d="M7.227 11.941c0 1.075.825 1.942 1.85 1.942h2.091c.892 0 1.617-.758 1.617-1.691 0-1.017-.442-1.376-1.1-1.609L8.327 9.416c-.659-.233-1.1-.591-1.1-1.608 0-.933.725-1.692 1.616-1.692h2.092c1.025 0 1.85.867 1.85 1.942M10 5v10" />
        <Path d="M9.997 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
