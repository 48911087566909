import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../config";

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/register`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ phone, email, password }, { rejectWithValue }) => {
    // return axios.post(`${API_URL}/auth/login`, {phone, email, password});
    try {
      const res = await axios.post(`${API_URL}/auth/login`, {
        phone,
        email,
        password,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const loginUserByOtp = createAsyncThunk(
  "auth/otp",
  async ({ to, code }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/otp`, {
        to,
        code,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const signUpAppleUser = createAsyncThunk(
  "auth/appleSignUp",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/signup/apple/mobile`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const loginAppleUser = createAsyncThunk(
  "auth/appleLogin",
  async ({ data }, { rejectWithValue }) => {
    // return axios.post(`${API_URL}/auth/login`, {phone, email, password});
    try {
      const res = await axios.post(`${API_URL}/auth/login/apple/mobile`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const loginGoogleUser = createAsyncThunk(
  "auth/googleLogin",
  async ({ data }, { rejectWithValue }) => {
    // return axios.post(`${API_URL}/auth/login`, {phone, email, password});
    try {
      const res = await axios.post(`${API_URL}/auth/login/google/mobile`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const loginFacebookUser = createAsyncThunk(
  "auth/facebookLogin",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${API_URL}/auth/login/facebook/mobile`,
        data
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
