import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={2}
      height={14}
      viewBox="0 0 2 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path d="M1 1v12" stroke="#D4D4D4" strokeLinecap="round" />
    </Svg>
  );
}

export default SvgComponent;
