import * as React from "react";
import {
  Button,
  Image,
  ScrollView,
  Text,
  useToast,
  View,
  Pressable,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import {
  CalendarGrey,
  GeoGreen,
  GreenEmail,
  GreenPhone,
  GreenUser,
  Height,
  Length,
  LocationGreen,
  TrailerClosed,
  TrailerOpen,
  VinGreen,
  Weight,
  Width,
} from "../../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../../networking/orders/ordersApi";
import { formatDate } from "../../../utils";
import { GOOGLE_API_KEY, imageHost } from "../../../config";
import { selectInputValues } from "../../../networking/orders/ordersSlice";
import BottomNavigator from "../../../components/BottomNavigator";
import { useNavigate } from "react-router-dom";
import {
  APIProvider,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";

export default function PreviewScreen() {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const order = useSelector(selectInputValues);
  const position = { lat: 40.6973709, lng: -74.1444872 };

  const {
    vin,
    brand,
    model,
    year,
    length,
    weight,
    height,
    width,
    additionalEquipment,
    customChanges,
    personalItems,
    pickUpLocation,
    deliveryLocation,
    pickUpDate1,
    deliveryDate1,
    inoperable,
    trailerType,
    recipientContactName,
    recipientContactPhone,
    recipientContactEmail,
    pickUpLocationCoordinates,
  } = order;
  const publish = () => {
    const newOrder = { ...order };
    newOrder.year = +order.year;
    newOrder.pickUpDate2 = "1";
    newOrder.deliveryDate2 = "1";
    newOrder.flexibleDates = order.flexibleDates || false;
    newOrder.assets = [];
    dispatch(createOrder({ data: newOrder }))
      .unwrap()
      .then(() => {
        navigate("/orders");
      })
      .catch((err) => {
        toast.show({
          description: err?.data?.message?.length
            ? err?.data?.message[0]
            : "Bad request",
          placement: "top",
        });
        console.log("err", err);
      });
  };

  const prevStep = () => {
    navigate("/create/4");
  };

  let imagesList = [];
  if (order?.images) {
    imagesList = [...order.images];
  }
  const [mainImage, setMainImage] = useState(imagesList[0] || null);

  const Directions = () => {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionService] = useState();
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map, pickUpLocation, deliveryLocation]);

    useEffect(() => {
      if (!directionsService || !directionsRenderer) return;
      const dirSer = new routesLibrary.DirectionsService();
      const dirRenderer = new routesLibrary.DirectionsRenderer({ map });
      // dirService
      dirSer
        .route({
          origin: pickUpLocation,
          destination: deliveryLocation,
          travelMode: google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: false,
        })
        .then((response) => {
          dirRenderer.setDirections(response);
          setRoutes(response.routes);
        });
    }, [directionsService, directionsRenderer]);
  };

  const MapD = () => {
    return (
      <APIProvider apiKey={GOOGLE_API_KEY}>
        <Map
          style={{ height: 400, marginTop: 10, width: "100%" }}
          defaultCenter={position}
          defaultZoom={10}
        >
          <Directions />
        </Map>
      </APIProvider>
    );
  };

  return (
    <View style={styles.container}>
      {!!mainImage && (
        <>
          <Image style={styles.image} src={imageHost + mainImage} />
        </>
      )}

      <ScrollView horizontal style={styles.imagesRow}>
        {imagesList?.map((image, index) => (
          <Pressable onPress={() => setMainImage(image)} key={index}>
            <Image
              style={styles.imageSmall}
              height={50}
              width={80}
              src={imageHost + image}
            />
          </Pressable>
        ))}
      </ScrollView>
      <Text style={styles.header}>
        {brand} {model}
      </Text>
      <Text style={styles.subheader}>Year {year}</Text>
      <View style={styles.badges}>
        <View mr={"4px"} ml={"4px"} style={styles.badge}>
          <Weight />
          <Text style={styles.badgeText}>{weight}</Text>
        </View>
        <View mr={"4px"} style={styles.badge}>
          <Length />
          <Text style={styles.badgeText}>{length}</Text>
        </View>
        <View mr={"4px"} ml={"4px"} style={styles.badge}>
          <Height />
          <Text style={styles.badgeText}>{height}</Text>
        </View>
        <View ml={"4px"} style={styles.badge}>
          <Width />
          <Text style={styles.badgeText}>{width}</Text>
        </View>
      </View>
      <View shadow="3" style={styles.vinBadge}>
        <VinGreen />
        <View style={styles.vinText}>
          <Text style={styles.greyText}>VIN code</Text>
          <Text>{vin}</Text>
        </View>
      </View>
      {!!additionalEquipment?.length && (
        <>
          <Text style={styles.headerBlock}>Additional equipment</Text>
          <Text>{additionalEquipment}</Text>
        </>
      )}
      {!!customChanges?.length && (
        <>
          <Text style={styles.headerBlock}>Car has custom changes/tuning</Text>
          <Text>{customChanges}</Text>
        </>
      )}
      {!!personalItems?.length && (
        <>
          <Text style={styles.headerBlock}>Personal items</Text>
          <Text>{personalItems}</Text>
        </>
      )}
      <>
        <Text style={styles.headerBlock}>Is car inoperable?</Text>
        <Text>{inoperable ? "Yes" : "No"}</Text>
      </>
      <>
        <Text style={styles.headerBlock}>Trailer Type</Text>
        {trailerType === "closed" && (
          <View style={styles.row}>
            <TrailerClosed />
            <Text style={styles.ml4} fontSize="sm">
              Enclosed Trailer
            </Text>
          </View>
        )}
        {trailerType === "open" && (
          <View style={styles.row}>
            <TrailerOpen />
            <Text style={styles.ml4} fontSize="sm">
              Open Trailer
            </Text>
          </View>
        )}
      </>
      <View style={styles.locationWrapper}>
        <LocationGreen />
        <View style={styles.locationTextWrapper}>
          <Text style={styles.locationText}>Pick Up Location</Text>
          <Text>{pickUpLocation}</Text>
          <View style={styles.dateRow}>
            <View style={styles.calendarIcon}>
              <CalendarGrey />
            </View>

            <Text>{formatDate(pickUpDate1)}</Text>
          </View>
        </View>
      </View>
      <View style={styles.locationWrapper}>
        <GeoGreen />
        <View style={styles.locationTextWrapper}>
          <Text style={styles.locationText}>Delivery Location</Text>
          <Text>{deliveryLocation}</Text>
          <View style={styles.dateRow}>
            <View style={styles.calendarIcon}>
              <CalendarGrey />
            </View>
            <Text>{formatDate(deliveryDate1)}</Text>
          </View>
        </View>
      </View>
      <MapD />
      <>
        <Text style={styles.headerBlock}>Recipient</Text>
        <View style={styles.recipientRow}>
          <GreenUser />
          <Text style={styles.locationTextWrapper}>{recipientContactName}</Text>
        </View>
        <View style={styles.recipientRow}>
          <GreenEmail />
          <Text style={styles.locationTextWrapper}>
            {recipientContactEmail}
          </Text>
        </View>
        <View style={styles.recipientRow}>
          <GreenPhone />
          <Text style={styles.locationTextWrapper}>
            {recipientContactPhone}
          </Text>
        </View>
      </>
      <View style={styles.emptySpace} />
      <BottomNavigator
        nextOnPress={publish}
        nextText={"Publish"}
        prevOnPress={prevStep}
        prevText={"Previous"}
      />
    </View>
  );
}
