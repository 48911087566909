import * as React from "react";
import { Button, FormControl, Input, View, Box, Text } from "native-base";
import styles from "./styles";
import { Email, Phone, User } from "../../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setUser } from "../../../networking/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";

export default function URFFirstScreen({ navigation }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {userInfo} = useSelector(state => state.auth);
  const [phone, setPhone] = useState(userInfo.phone);
  const [email, setEmail] = useState(userInfo.email);
  const [fullName, setFullName] = useState(userInfo.fullName);

  const goToNextPage = () => {
    dispatch(
      setUser({
        email,
        phone,
        fullName,
      }),
    );
    navigate("/signup/user/2");
  };
  return (
    <Box style={styles.wrapper}>
      <View style={styles.container}>
        <Text fontSize="2xl" bold textAlign={"center"}>
          Registration
        </Text>
        <FormControl>
          <FormControl.Label>Full Name</FormControl.Label>
          <Input
            value={fullName}
            onChangeText={setFullName}
            type="text"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <User />
              </View>
            }
            placeholder="Full Name"
          />
          <View style={styles.divider} />
          <FormControl.Label>Email</FormControl.Label>
          <Input
            value={email}
            onChangeText={setEmail}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            type="email"
            autoCorrect={false}
            autoCompleteType="email"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Email />
              </View>
            }
            placeholder="Email Address"
          />
          <View style={styles.divider} />
          <FormControl.Label>Phone Number</FormControl.Label>
          <Input
            type="tel"
            value={phone}
            onChangeText={setPhone}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Phone />
              </View>
            }
            placeholder="Enter your Phone Number"
          />
          <View style={styles.divider} />
        </FormControl>
        <Button style={styles.button} onPress={goToNextPage}>
          Continue
        </Button>
        <View style={styles.divider} />
      </View>
    </Box>
  );
}
