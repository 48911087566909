import * as React from "react";
import {
  Center,
  Checkbox,
  FormControl,
  Input,
  Pressable,
  Select,
  Spinner,
  Text,
  TextArea,
  View,
} from "native-base";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import {
  FirstStep,
  Vin,
  Model,
  Year,
  Length,
  Height,
  Width,
  CheckboxOn,
  CheckboxOff,
  TrailerClosed,
  TrailerOpen,
  Scanner,
  Weight,
  Upload,
} from "../../../assets/images/svg";
import { carsList, carsObj } from "../../../assets/data/carsList";
import { useEffect, useState } from "react";
import instance from "../../../networking/axios";
import { API_URL } from "../../../config";
import { useNavigate } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import ReactImageUploading from "react-images-uploading";

export default function NOFFirstStepScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vin = useSelector((state) => state.order.vin);
  const height = useSelector((state) => state.order.height);
  const width = useSelector((state) => state.order.width);
  const weight = useSelector((state) => state.order.weight);
  const inoperable = useSelector((state) => state.order.inoperable);
  const trailerType = useSelector((state) => state.order.trailerType);
  const [models, setModels] = useState([]);
  const assets = useSelector((state) => state.order.assets);
  const images = useSelector((state) => state.order.images);
  const [showLoader, setShowLoader] = useState(false);

  const createYearArray = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    const yearArray = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearArray.push(year.toString());
    }

    return yearArray.reverse();
  };
  const years = createYearArray();

  const nextStep = () => {
    navigate("/create/2");
  };

  const cancel = () => {
    navigate("/orders");
  };

  const changeModel = (value) => {
    setModels(carsObj[value]);
    handleChange("brand", value);
  };

  const onChange = (imageList, addUpdateIndex) => {
    if (addUpdateIndex) {
      uploadResource(imageList[addUpdateIndex[0]]);
    }
    handleChange("assets", imageList);
  };

  const uploadResource = async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = await instance.post(`${API_URL}/orders/upload`, formData);
      if (result?.data?.fileName) {
        dispatch(
          setInputValue({
            key: "images",
            value: [...images, result?.data?.fileName],
          })
        );
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  useEffect(() => {});

  // useEffect(() => {
  //   dispatch(clearInputValues());
  // }, [dispatch]);

  const handleChange = (key, value) => {
    dispatch(setInputValue({ key, value }));
  };

  const getByVin = async (num) => {
    if (num?.length !== 17) {
      return;
    }
    setShowLoader(true);
    const res = await instance.get(API_URL + "/orders/vin/" + num);
    if (res?.data) {
      handleChange("brand", res?.data?.brand || "");
      handleChange("model", res?.data?.model || "");
      handleChange("height", res?.data?.height || "");
      handleChange("width", res?.data?.width || "");
      handleChange("weight", res?.data?.weight || "");
      handleChange("length", res?.data?.length || "");
      handleChange("year", res?.data?.year || "");
    }
    setShowLoader(false);
  };

  const LengthInput = () => {
    const length = useSelector((state) => state.order.length);
    return (
      <Input
        value={length}
        onChangeText={(value) => {
          dispatch(setInputValue({ key: "length", value }));
        }}
        type="text"
        InputLeftElement={
          <View style={styles.leftIcon}>
            <Length />
          </View>
        }
        placeholder="Enter length"
      />
    );
  };

  const AdditionalEquipmentTextArea = () => {
    const additionalEquipment = useSelector(
      (state) => state.order.additionalEquipment
    );
    return (
      <TextArea
        value={additionalEquipment}
        onChangeText={(value) => {
          handleChange("additionalEquipment", value);
        }}
        numberOfLines={2}
        placeholder="Enter additional equipment"
      />
    );
  };

  const CarCustomChangesTextArea = () => {
    const hasCustom = useSelector((state) => state.order.hasCustom);
    const customChanges = useSelector((state) => state.order.customChanges);
    return (
      <>
        <View style={styles.row}>
          <Center>
            <Checkbox
              value="checkbox"
              onChange={(value) => {
                handleChange("hasCustom", value);
              }}
              accessibilityLabel="checkbox"
            />
          </Center>

          <Text style={styles.textContainer} fontSize="sm">
            Car has custom changes/tuning
          </Text>
        </View>
        {!!hasCustom && (
          <>
            <View style={styles.divider} />
            <TextArea
              value={customChanges}
              onChangeText={(value) => {
                handleChange("customChanges", value);
              }}
              numberOfLines={2}
              placeholder="Please, describe the changes added in the construction"
            />
          </>
        )}
      </>
    );
  };

  const CarPersonalItemsTextArea = () => {
    const personalItems = useSelector((state) => state.order.personalItems);
    const havePersonal = useSelector((state) => state.order.havePersonal);

    return (
      <>
        <View style={styles.row}>
          <Center>
            <Checkbox
              value="checkbox"
              onChange={(value) => {
                handleChange("havePersonal", value);
              }}
              accessibilityLabel="checkbox"
            />
          </Center>

          <Text style={styles.textContainer} fontSize="sm">
            I have personal items in my car
          </Text>
        </View>
        {havePersonal && (
          <>
            <View style={styles.divider} />
            <TextArea
              value={personalItems}
              onChangeText={(value) => {
                handleChange("personalItems", value);
              }}
              numberOfLines={2}
              placeholder="Please, list the items to be transported and indicate their total weight"
            />
          </>
        )}
      </>
    );
  };

  const BrandSelect = () => {
    const brand = useSelector((state) => state.order.brand);
    return (
      <Select
        InputLeftElement={
          <View style={styles.leftIcon}>
            <Model />
          </View>
        }
        selectedValue={brand}
        onValueChange={changeModel}
        minWidth="200"
        accessibilityLabel="brand"
        placeholder="Select"
        mt="1"
      >
        {carsList.map((car) => (
          <Select.Item key={car} label={car} value={car} />
        ))}
      </Select>
    );
  };

  const ModelSelect = () => {
    const brand = useSelector((state) => state.order.brand);
    const model = useSelector((state) => state.order.model);
    return (
      <Select
        isDisabled={!brand?.length}
        onValueChange={(value) => {
          handleChange("model", value);
        }}
        selectedValue={model}
        InputLeftElement={
          <View style={styles.leftIcon}>
            <Model />
          </View>
        }
        minWidth="200"
        accessibilityLabel="models"
        placeholder="Select"
        mt="1"
      >
        {models.map((model) => (
          <Select.Item key={model} label={model} value={model} />
        ))}
        {models.length === 0 && !!model && (
          <Select.Item key={model} label={model} value={model} />
        )}
      </Select>
    );
  };

  const YearSelect = () => {
    const year = useSelector((state) => state.order.year);
    return (
      <Select
        selectedValue={year}
        onValueChange={(value) => {
          handleChange("year", value);
        }}
        InputLeftElement={
          <View style={styles.leftIcon}>
            <Year />
          </View>
        }
        minWidth="200"
        accessibilityLabel="year"
        placeholder="Select"
        mt="1"
      >
        {years.map((year) => (
          <Select.Item key={year} label={year} value={year} />
        ))}
      </Select>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.h1}>New Order</Text>
      <View style={styles.stepper}>
        <FirstStep />
      </View>
      <View>
        {showLoader && (
          <View
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 10,
              paddingTop: 200,
            }}
          >
            <Spinner size="lg" />
          </View>
        )}
        <View style={styles.divider} />
        <View style={styles.inputsGrid}>
          <View style={styles.inputGrid}>
            <FormControl.Label
              style={{
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              VIN
            </FormControl.Label>
            <View style={styles.vinInput}>
              <Input
                autoCapitalize={"characters"}
                type="text"
                value={vin}
                onChangeText={(value) => {
                  handleChange("vin", value);
                }}
                onBlur={(e) => {
                  getByVin(e?.nativeEvent?.text || "");
                }}
                InputLeftElement={
                  <View style={styles.leftIcon}>
                    <Vin />
                  </View>
                }
                placeholder="VIN"
              />
            </View>
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Brand</FormControl.Label>
            <BrandSelect />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Model</FormControl.Label>
            <ModelSelect />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Year</FormControl.Label>
            <YearSelect />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Weight</FormControl.Label>
            <Input
              value={weight}
              onChangeText={(value) => {
                handleChange("weight", value);
              }}
              type="text"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Weight />
                </View>
              }
              placeholder="Enter weight"
            />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Length</FormControl.Label>
            <LengthInput />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Height</FormControl.Label>
            <Input
              value={height}
              onChangeText={(value) => {
                handleChange("height", value);
              }}
              type="text"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Height />
                </View>
              }
              placeholder="Enter height"
            />
          </View>
          <View style={styles.inputGrid}>
            <FormControl.Label>Width</FormControl.Label>
            <Input
              value={width}
              onChangeText={(value) => {
                handleChange("width", value);
              }}
              type="text"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Width />
                </View>
              }
              placeholder="Enter width"
            />
          </View>
        </View>
        <View style={styles.divider} />
        <FormControl.Label>Is car inoperable?</FormControl.Label>
        <View style={styles.row}>
          <Pressable
            onPress={() => handleChange("inoperable", true)}
            style={styles.row}
          >
            {inoperable === true ? <CheckboxOn /> : <CheckboxOff />}
            <Text style={styles.textContainer} fontSize="sm">
              Yes
            </Text>
          </Pressable>
          <Pressable
            onPress={() => handleChange("inoperable", false)}
            style={[styles.row, styles.ml16]}
          >
            {inoperable === false ? <CheckboxOn /> : <CheckboxOff />}
            <Text style={styles.textContainer} fontSize="sm">
              No
            </Text>
          </Pressable>
        </View>
        <View style={styles.divider} />
        <FormControl.Label>Trailer Type</FormControl.Label>
        <View style={styles.row}>
          <Pressable
            onPress={() => handleChange("trailerType", "closed")}
            style={styles.row}
          >
            {trailerType === "closed" ? <CheckboxOn /> : <CheckboxOff />}
            <TrailerClosed style={styles.ml8} />
            <Text style={styles.ml4} fontSize="sm">
              Enclosed Trailer
            </Text>
          </Pressable>
          <Pressable
            onPress={() => handleChange("trailerType", "open")}
            style={[styles.row, styles.ml16]}
          >
            {trailerType === "open" ? <CheckboxOn /> : <CheckboxOff />}
            <TrailerOpen style={styles.ml8} />
            <Text style={styles.ml4} fontSize="sm">
              Open Trailer
            </Text>
          </Pressable>
        </View>
        <View style={styles.divider} />
        <FormControl.Label>Additional equipment</FormControl.Label>
        <AdditionalEquipmentTextArea />
        <View style={styles.divider} />
        <CarCustomChangesTextArea />
        <View style={styles.divider} />
        <CarPersonalItemsTextArea />
        <View style={styles.divider} />
        <ReactImageUploading
          multiple
          value={assets}
          onChange={onChange}
          maxNumber={10}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <div
                style={styles.dragImages}
                onClick={onImageUpload}
                {...dragProps}
              >
                <Upload />
                <div style={styles.uploadText}>Drag & drop files or Browse</div>
                <div style={styles.supportedText}>
                  Supported formates: JPEG, PNG
                </div>
              </div>
              <View style={{ flexDirection: "row" }}>
                {imageList.map((image, index) => (
                  <div key={index} style={{ marginTop: 10, marginRight: 10 }}>
                    <img src={image["data_url"]} alt="" width="100" />
                    <div style={{ textAlign: "center" }}>
                      <button onClick={() => onImageRemove(index)}>
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </View>
            </div>
          )}
        </ReactImageUploading>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 10,
            marginTop: 15,
          }}
        >
          {/*{assets.map((image, index) => (*/}
          {/*  <TouchableOpacity onPress={() => {}} key={index}>*/}
          {/*    <Image*/}
          {/*      alt={image.uri}*/}
          {/*      style={styles.image}*/}
          {/*      height={66}*/}
          {/*      width={66}*/}
          {/*      src={image.uri}*/}
          {/*    />*/}
          {/*  </TouchableOpacity>*/}
          {/*))}*/}
        </View>
        <View style={styles.emptySpace} />
        <BottomNavigator
          nextOnPress={nextStep}
          nextText={"Next"}
          prevOnPress={cancel}
          prevText={"Cancel"}
        />
      </View>
    </View>
  );
}
