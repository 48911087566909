import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G
        clipPath="url(#clip0_4017_6111)"
        stroke="#1DCE80"
        strokeWidth={1.5}
        strokeLinecap="round">
        <Path d="M4.168 14.167h11.667c1.666 0 2.5-.834 2.5-2.5V8.333c0-1.666-.834-2.5-2.5-2.5H4.168c-1.667 0-2.5.834-2.5 2.5v3.334c0 1.666.833 2.5 2.5 2.5zM15 5.833V10M5 5.833v3.334M8.374 5.833L8.332 10M11.668 5.833v2.5" />
      </G>
      <Defs>
        <ClipPath id="clip0_4017_6111">
          <Path fill="#fff" d="M0 0H20V20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
