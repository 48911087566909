import * as React from 'react';
import Svg, {Circle, Ellipse} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Circle
        cx={10}
        cy={10}
        r={9}
        fill="#FAFAFA"
        stroke="#17A566"
        strokeWidth={2}
      />
      <Ellipse cx={10} cy={10} rx={5} ry={5} fill="#17A566" />
    </Svg>
  );
}

export default SvgComponent;
