import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  stepper: {
    alignItems: "center",
  },
  leftIcon: {
    marginLeft: 12,
  },
  divider: {
    height: 16,
  },
  emptySpace: {
    height: 50,
  },
  addPhotoContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  addPhotoText: {
    fontSize: 16,
    fontWeight: "600",
    color: "#171717",
    marginLeft: 12,
  },
  dateRow: {
    flexDirection: "row",
  },
  date: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  dateButtonContainer: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#E5E5E5",
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
  },
  dateButtonText: {
    color: "#A3A3A3",
    fontSize: 16,
    marginLeft: 8,
  },
  row: {
    flexDirection: "row",
    marginTop: 16,
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
  },
  inputBorder: {
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#E5E5E5",
    padding: 12,
  },
});

export default styles;
