import * as React from "react";
import {
  Button,
  FormControl,
  Input,
  useToast,
  View,
  Text,
  Box,
  Pressable,
} from "native-base";
import styles from "./styles";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../../config";
import { useNavigate } from "react-router-dom";

export default function RefreshPasswordScreen({ route, navigation }) {
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const toast = useToast();
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;

  const backToSignIn = () => {
    navigate("/signIn");
  };
  const validateEmail = (input) => {
    return emailRegex.test(input);
  };

  const refreshPassword = () => {
    if (login.length > 1) {
      axios
        .get(API_URL + "/auth/otp/" + login)
        .then((data) => {
          if (validateEmail(login)) {
            navigate("/confirm-password/email/" + login);
          } else {
            navigate("/confirm-password/phone/" + login);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.show({
            description: "User not found",
            placement: "top",
          });
        });
    }
  };

  return (
    <Box style={styles.wrapper}>
      <View style={styles.container}>
        <Text fontSize={"2xl"} bold textAlign={"center"}>
          Password Refresh
        </Text>
        <Text textAlign={"center"} py={4}>
          Please, enter your phone number/email address used for the
          registration, and we will send you a confirmation code to refresh your
          password.
        </Text>
        <FormControl.Label>Phone Number/Email Address</FormControl.Label>
        <Input
          w={"100%"}
          type="text"
          onChangeText={setLogin}
          placeholder="Enter your Phone Number/Email Address"
        />

        <Button style={styles.button} onPress={refreshPassword}>
          Refresh Password
        </Button>

        <Pressable
          textAlign={"center"}
          style={styles.actionText}
          onPress={backToSignIn}
        >
          Back to Sign In
        </Pressable>
      </View>
    </Box>
  );
}
