import * as React from "react";
import { Logo } from "../../assets/images/svg";
import styles from "./styles";
import { Button, View, Center, Pressable } from "native-base";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserProfileButton from "./userProfileButton";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const userToken = useSelector((state) => state?.auth?.userToken);
  const name = useSelector((state) => state?.profile?.fullName);

  const [signIn, setSignIn] = useState(true);
  useEffect(() => {
    const route = location?.pathname || "";
    if (route.search("signin") > 0) {
      setSignIn(false);
    } else {
      setSignIn(true);
    }
  }, [location]);

  return (
    <View>
      <View style={styles.header}>
        <Pressable
          onPress={() => {
            userToken ? navigate("/orders") : navigate("/");
          }}
        >
          <Logo />
        </Pressable>
        {!userToken && (
          <Center>
            {signIn ? (
              <Button
                style={styles.button}
                onPress={() => {
                  navigate("/signin");
                }}
              >
                Sign In
              </Button>
            ) : (
              <Button
                style={styles.button}
                onPress={() => {
                  navigate("/signup");
                }}
              >
                Sign Up
              </Button>
            )}
          </Center>
        )}
        {!!userToken && <UserProfileButton name={name} />}
      </View>
      <View style={{ marginTop: 104 }}>
        <Outlet />
      </View>
    </View>
  );
}
