import {Dimensions, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  stepper: {
    alignItems: 'center',
  },
  leftIcon: {
    marginLeft: 12,
  },
  divider: {
    height: 16,
  },
  emptySpace: {
    height: 50,
  },
  addPhotoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addPhotoText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#171717',
    marginLeft: 12,
  },
  dateButtonContainer: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: '#E5E5E5',
    flexDirection: 'row',
    paddingTop: 12,
    paddingRight: 12,
    paddingLeft: 12,
    paddingBottom: 4,
  },
  maps: {
    width: Dimensions.get('screen').width,
    height: 300,
  },
});

export default styles;
