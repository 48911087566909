import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G stroke="#1DCE80" strokeLinecap="round" strokeLinejoin="round">
        <Path
          d="M6.668 1.667v2.5M13.332 1.667v2.5M2.918 7.575h14.167M17.5 7.083v7.084c0 2.5-1.25 4.166-4.167 4.166H6.667c-2.917 0-4.167-1.666-4.167-4.166V7.083c0-2.5 1.25-4.166 4.167-4.166h6.666c2.917 0 4.167 1.666 4.167 4.166z"
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <Path
          d="M13.08 11.417h.008M13.08 13.917h.008M9.998 11.417h.008M9.998 13.917h.008M6.912 11.417h.008M6.912 13.917h.008"
          strokeWidth={2}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
