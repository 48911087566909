import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M8.333 18.333h3.334c4.166 0 5.833-1.666 5.833-5.833v-5c0-4.167-1.667-5.833-5.833-5.833H8.333C4.167 1.667 2.5 3.333 2.5 7.5v5c0 4.167 1.667 5.833 5.833 5.833z"
        stroke="#1DCE80"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.375 6.908a6.588 6.588 0 00-8.75 0l1.817 2.917a3.844 3.844 0 015.116 0l1.817-2.917z"
        stroke="#1DCE80"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
