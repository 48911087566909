import * as React from "react";
import {
  Text,
  View,
  FlatList,
  AlertDialog,
  Button,
  Select,
  FormControl,
  Input,
  Pressable,
} from "native-base";
import { StarRatingDisplay } from "react-native-star-rating-widget";
import {
  ApplePay,
  CreditCard,
  GooglePay,
  PaymentForm,
} from "react-square-web-payments-sdk";

import styles from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  acceptBid,
  declineBid,
  getOrderBids,
} from "../../networking/orders/ordersApi";
import { Dollar } from "../../assets/images/svg";
import { useNavigate, useParams } from "react-router-dom";
import { SquareAppId, SquareLocationId } from "../../config";

export default function BidsScreen({ showTitle = true }) {
  const { id } = useParams();
  const orders = useSelector((state) => state.profile.orders);
  const order = orders.find((order) => order.id === id);
  const { brand, model, year } = order;
  const acceptRef = React.useRef(null);
  const declineRef = React.useRef(null);
  const counterBidRef = React.useRef(null);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
  const [counterBidModalIsOpen, setCounterBidModalIsOpen] = useState(false);
  const [bids, setBids] = React.useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [sorting, setSorting] = useState("");
  const [currentBid, setCurrentBid] = useState({});
  const { fullName, email, phone } = useSelector((state) => state.profile);
  const navigate = useNavigate();

  let counterPrice = 0;

  const getBids = () => {
    setRefreshing(true);
    setSorting("");
    dispatch(getOrderBids({ id: order.id }))
      .then(({ payload }) => {
        setRefreshing(false);
        setBids(payload);
      })
      .catch(() => {
        setRefreshing(false);
      });
  };

  useEffect(() => {
    getBids();
  }, []);

  const dispatch = useDispatch();

  const sortList = [
    { name: "Price", value: "bidAmount" },
    { name: "Rating", value: "carrierRating" },
  ];

  const EmptyBids = () => {
    return (
      <View style={styles.center}>
        <Text style={styles.centerText}>No Bids</Text>
      </View>
    );
  };

  const Bid = ({ bid }) => {
    const { carrierName, bidAmount, carrierRating } = bid;
    return (
      <View style={styles.bidWrapper}>
        <View style={styles.ratingWrapper}>
          <Text style={styles.bidName} numberOfLines={1}>
            {carrierName}
          </Text>
          {carrierRating > 0 ? (
            <StarRatingDisplay
              starSize={20}
              starStyle={styles.starStyle}
              rating={carrierRating}
            />
          ) : (
            <Text>No Rating</Text>
          )}
        </View>

        <Text style={styles.bidPrice}>Bid: ${bidAmount}</Text>
        <View style={styles.buttonsWrapper}>
          <Pressable
            onPress={() => {
              setCurrentBid(bid);
              setCounterBidModalIsOpen(true);
            }}
            style={[styles.button, styles.counterButton]}
          >
            <Text style={styles.counterButtonText}>Counter-bid</Text>
          </Pressable>
          <View style={styles.divider} />
          <Pressable
            onPress={() => {
              setCurrentBid(bid);
              setAcceptModalIsOpen(true);
            }}
            style={[styles.button, styles.acceptButton]}
          >
            <Text style={styles.buttonText}>Accept</Text>
          </Pressable>
          <View style={styles.divider} />
          <Pressable
            onPress={() => {
              setCurrentBid(bid);
              setDeclineModalIsOpen(true);
            }}
            style={[styles.button, styles.declineButton]}
          >
            <Text style={styles.buttonText}>Decline</Text>
          </Pressable>
        </View>
      </View>
    );
  };

  const CounterBidAlert = () => {
    const onClose = () => {
      setCounterBidModalIsOpen(false);
    };
    const onSend = () => {
      setCounterBidModalIsOpen(false);
      dispatch(
        declineBid({
          data: {
            bidID: currentBid.bidID,
            carrierID: currentBid.carrierID,
            bidAmount: counterPrice,
            status: "counterBid",
          },
        })
      ).then(() => {
        getBids();
      });
    };
    return (
      <AlertDialog
        leastDestructiveRef={counterBidRef}
        isOpen={counterBidModalIsOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Counter Bid</AlertDialog.Header>
          <AlertDialog.Body>
            Please, offer your price for the Order
            <FormControl.Label>Email</FormControl.Label>
            <Input
              onChangeText={(e) => {
                counterPrice = e;
              }}
              type="text"
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCapitalize="none"
              autoCorrect={false}
              autoCompleteType="email"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Dollar />
                </View>
              }
              placeholder="Enter price"
            />
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={onSend}>
                Send
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const AcceptAlert = () => {
    const accept = () => {
      setAcceptModalIsOpen(false);
    };
    const onClose = () => {
      setAcceptModalIsOpen(false);
    };
    return (
      <AlertDialog
        leastDestructiveRef={acceptRef}
        isOpen={acceptModalIsOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Accept Bid</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to accept the bid? If you accept this Bid all
            other bids will be automatically declined and money will be charged
            from your account
            <PaymentForm
              applicationId={SquareAppId}
              cardTokenizeResponseReceived={(token, verifiedBuyer) => {
                dispatch(
                  acceptBid({
                    data: {
                      ...currentBid,
                      cardDetails: { nonce: token.token },
                      status: "accept",
                    },
                  })
                ).then((data) => {
                  if (data?.payload?.accepted) {
                    setAcceptModalIsOpen(false);
                    navigate("/orders");
                  } else {
                    alert("Payment Error");
                  }
                });
              }}
              locationId={SquareLocationId}
              createPaymentRequest={() => ({
                countryCode: "US",
                currencyCode: "USD",
                total: {
                  amount: currentBid?.bidAmount?.toString(),
                  label: currentBid?.carrierName || "",
                },
              })}
              createVerificationDetails={() => ({
                amount: currentBid?.bidAmount?.toString(),
                currencyCode: "USD",
                intent: "CHARGE",
                billingContact: {
                  familyName: fullName,
                  email: email,
                  phone: phone,
                },
              })}
            >
              <View style={{ height: 10 }} />
              <ApplePay />
              <GooglePay />
              <View style={{ height: 10 }} />
              <CreditCard
                buttonProps={{
                  css: {
                    backgroundColor: "rgb(29, 206, 128)",
                    fontSize: "14px",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "rgb(14, 116, 144)",
                    },
                  },
                }}
              />
            </PaymentForm>
          </AlertDialog.Body>

          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={acceptRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={accept}>
                Card
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const DeclineAlert = () => {
    const onClose = () => {
      setDeclineModalIsOpen(false);
    };
    const onDecline = () => {
      setDeclineModalIsOpen(false);
      dispatch(
        declineBid({
          data: {
            bidID: currentBid.bidID,
            carrierID: currentBid.carrierID,
            bidAmount: 0,
            status: "decline",
          },
        })
      ).then(() => {
        getBids();
      });
    };
    return (
      <AlertDialog
        leastDestructiveRef={declineRef}
        isOpen={declineModalIsOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Decline Bid</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to decline the bid?
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={declineRef}
              >
                Cancel
              </Button>
              <Button style={styles.dcButton} onPress={onDecline}>
                Decline
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const sortBy = (key) => {
    const sorted = bids.sort(function (a, b) {
      const keyA = a[key],
        keyB = b[key];
      if (keyA < keyB) {
        return key === "carrierRating" ? 1 : -1;
      }
      if (keyA > keyB) {
        return key === "carrierRating" ? -1 : 1;
      }
      return 0;
    });
    setBids([...sorted]);
  };

  return (
    <View style={styles.container}>
      {!!showTitle && (
        <Text style={styles.pageName}>
          {brand} {model} {year}
        </Text>
      )}
      <Select
        minWidth="200"
        accessibilityLabel="State"
        placeholder="Sort By"
        mt="1"
        selectedValue={sorting}
        onValueChange={(itemValue) => {
          setSorting(itemValue);
          sortBy(itemValue);
        }}
        mb="5"
      >
        {sortList.map((item) => (
          <Select.Item key={item.value} label={item.name} value={item.value} />
        ))}
      </Select>
      {!bids?.length && !refreshing && <EmptyBids />}
      {!!bids?.length && (
        <FlatList
          refreshing={refreshing}
          data={bids}
          renderItem={({ item }) => <Bid bid={item} />}
          onRefresh={getBids}
        />
      )}
      <AcceptAlert />
      <DeclineAlert />
      <CounterBidAlert />
    </View>
  );
}
