import * as React from "react";
import {
  Box,
  Button,
  Center,
  FormControl,
  Input,
  Text,
  View,
  WarningOutlineIcon,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import { Email, Eye, EyeSlash, Lock, Phone } from "../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../networking/auth/authApi";
import { useToast } from "native-base";
import { clearErrorMessage } from "../../networking/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "../../config/constants";

export default function SignInScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location?.pathname;
  const dispatch = useDispatch();
  const toast = useToast();
  const { loading, success, message } = useSelector((state) => state.auth);

  const [hidden, setHidden] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (success) {
      navigate("/orders");
    }
  }, [success]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, []);

  useEffect(() => {
    if (!success && message) {
      toast.show({
        description: message,
        placement: "top",
      });
    }
  }, [loading, success, message]);

  const showPassword = () => {
    setHidden(!hidden);
  };

  const goToSignUp = () => {
    if (type === "/signin/email") {
      navigate("/signup/email");
    } else {
      navigate("/signup/phone");
    }
  };

  const signIn = () => {
    const credentials = { password };
    if (type === "/signin/email") {
      credentials.email = email;
    }
    if (type === "/signin/phone") {
      credentials.phone = phone;
    }
    dispatch(
      loginUser({
        ...credentials,
      })
    );
  };

  return (
    <Box style={styles.wrapper}>
      <View style={isMobile() ? styles.containerMobile : styles.container}>
        <Center width="282">
          <Text fontSize="2xl" bold>
            Sign In
          </Text>
          <Text fontSize="md">Welcome back, Sign In to your account</Text>
        </Center>
        <FormControl>
          {type === "/signin/email" && (
            <>
              <FormControl.Label>Email</FormControl.Label>
              <Input
                value={email}
                onChangeText={setEmail}
                type="email"
                textContentType="emailAddress"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
                autoCompleteType="email"
                InputLeftElement={
                  <View style={styles.leftIcon}>
                    <Email />
                  </View>
                }
                placeholder="Email Address"
              />
              <FormControl.ErrorMessage
                leftIcon={<WarningOutlineIcon size="xs" />}
              >
                Try different from previous passwords.
              </FormControl.ErrorMessage>
            </>
          )}
          {type === "/signin/phone" && (
            <>
              <FormControl.Label>Phone Number</FormControl.Label>
              <Input
                value={phone}
                onChangeText={setPhone}
                type="text"
                InputLeftElement={
                  <View style={styles.leftIcon}>
                    <Phone />
                  </View>
                }
                placeholder="Enter your Phone Number"
              />
            </>
          )}
          <View style={styles.divider} />
          <FormControl.Label>Password</FormControl.Label>
          <Input
            value={password}
            onChangeText={setPassword}
            type={hidden ? "password" : "text"}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Lock />
              </View>
            }
            InputRightElement={
              <View onPress={showPassword} style={styles.rightIcon}>
                {hidden ? <EyeSlash /> : <Eye />}
              </View>
            }
            placeholder="Password"
          />
          <Text
            style={[styles.actionText, styles.forgotPassword]}
            onPress={() => {
              navigate("/refresh-password");
            }}
          >
            Forgot password?
          </Text>
          <View style={styles.divider} />
        </FormControl>
        <Button style={styles.button} onPress={signIn}>
          Continue
        </Button>
        <View style={styles.divider} />
        <View>
          <Text fontSize="sm">
            Don’t have an account?{" "}
            <Text style={styles.actionText} onPress={goToSignUp}>
              Sign Up
            </Text>
          </Text>
        </View>
        <View style={styles.divider} />
      </View>
    </Box>
  );
}
