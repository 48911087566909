import * as React from 'react';
import {Box, Button, Text} from 'native-base';
import styles from './styles';
import {useNavigate} from 'react-router-dom';
import {isMobile} from "../../config/constants";

export default function StartScreen() {
    const navigate = useNavigate();
    const signUp = ()=>{
        navigate('/signup')
    }
    const signIn = ()=>{
        navigate('/signin')
    }
    return (
        <Box style={styles.wrapper}>
            <Box style={isMobile() ? null : styles.container}>
                <Text style={styles.text} bold >Welcome to Skip Your Broker</Text>
                <Button
                    style={styles.button}
                    onPress={signUp}>
                    Sign Up
                </Button>
                <Button
                    style={styles.button}
                    variant="outline"
                    colorScheme="secondary"
                    onPress={signIn}>
                    Sign In
                </Button>
            </Box>
        </Box>
    );
}
