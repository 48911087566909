import * as React from "react";
import {
  Button,
  FormControl,
  View,
  Text,
  Center,
  Checkbox,
  Input,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import { ThirdStep, Year } from "../../../assets/images/svg";
import { formatDate } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useNavigate } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";

export default function NOFThirdStepScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = new Date();
  const nextStep = () => {
    navigate("/create/4");
  };

  const prevStep = () => {
    navigate("/create/2");
  };

  const [calendar, setCalendar] = useState("");
  const [open, setOpen] = useState(false);
  const flexibleDates = useSelector((state) => state.order.flexibleDates);
  const pickUpDate1 = useSelector((state) => state.order.pickUpDate1);
  const deliveryDate1 = useSelector((state) => state.order.deliveryDate1);

  // useEffect(() => {
  //   if (!pickUpDate1) {
  //     handleChange("pickUpDate1", new Date());
  //   }
  //   if (!deliveryDate1) {
  //     handleChange("deliveryDate1", new Date());
  //   }
  // }, []);

  const handleChange = (key, value) => {
    dispatch(setInputValue({ key, value }));
  };

  return (
    <View style={styles.container}>
      <View style={styles.stepper}>
        <ThirdStep />
      </View>
      <View>
        <View style={styles.divider} />
        <FormControl.Label>Pick Up Date</FormControl.Label>
        <View style={styles.dateRow}>
          <View style={styles.inputBorder}>
            <input
              style={{
                borderWidth: 0,
                color: "#A3A3A3",
              }}
              onChange={(e) => {
                handleChange("pickUpDate1", e.target.value);
              }}
              value={pickUpDate1}
              type="date"
              name="pickUpDate1"
            />
          </View>
        </View>

        <View style={styles.divider} />

        <FormControl.Label>Delivery Date</FormControl.Label>
        <View style={styles.dateRow}>
          <View style={styles.inputBorder}>
            <input
              style={{
                borderWidth: 0,
                color: "#A3A3A3",
              }}
              onChange={(e) => {
                handleChange("deliveryDate1", e.target.value);
              }}
              value={deliveryDate1}
              type="date"
              name="deliveryDate1"
            />
          </View>
        </View>
        <View style={styles.row}>
          <Center>
            <Checkbox
              value="checkbox"
              defaultIsChecked={flexibleDates}
              onChange={(state) => {
                handleChange("flexibleDates", state);
              }}
              accessibilityLabel="checkbox"
            />
          </Center>

          <Text style={styles.textContainer} fontSize="sm">
            Flexible Dates
          </Text>
        </View>

        <View style={styles.emptySpace} />
      </View>
      {/*<DatePicker*/}
      {/*  modal*/}
      {/*  minimumDate={pickUpDate1 || new Date()}*/}
      {/*  mode={"date"}*/}
      {/*  date={date}*/}
      {/*  open={open}*/}
      {/*  onConfirm={(date) => {*/}
      {/*    setOpen(false);*/}
      {/*    setDate(date);*/}
      {/*  }}*/}
      {/*  onCancel={() => {*/}
      {/*    setOpen(false);*/}
      {/*  }}*/}
      {/*/>*/}
      <BottomNavigator
        nextOnPress={nextStep}
        nextText={"Next"}
        prevOnPress={prevStep}
        prevText={"Previous"}
      />
    </View>
  );
}
