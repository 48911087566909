import * as React from "react";
import {
  Button,
  FormControl,
  Input,
  useToast,
  View,
  Pressable,
  Text,
  Box,
} from "native-base";
import styles from "./styles";
import { useState } from "react";
import { Eye, EyeSlash, Lock } from "../../../assets/images/svg";
import axios from "axios";
import { API_URL } from "../../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../../../networking/auth/authSlice";

export default function ChangePasswordScreen({ route, navigation }) {
  const navigate = useNavigate();
  const params = useLocation();

  // const { token } = route.params;
  // navigation.setOptions({
  //   name: "Change Password",
  //   subheader: "Please, enter new password.",
  // });
  const [hidden, setHidden] = useState(true);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const toast = useToast();

  const showPassword = () => {
    setHidden(!hidden);
  };

  const confirmPassword = () => {
    if (password && password.length > 5 && password === password2) {
      axios
        .put(
          `${API_URL}/auth/password`,
          {
            password,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          setToken(token);
          navigation.reset({
            index: 0,
            routes: [{ name: "OrdersList" }],
          });
        })
        .catch((err) => console.log("err", err));
    } else {
      toast.show({
        description: "Incorrect password",
        placement: "top",
      });
    }
  };

  return (
    <Box style={styles.wrapper}>
      <View style={styles.container}>
        <Text fontSize="2xl" bold textAlign={"center"}>
          Change Password
        </Text>
        <Text textAlign={"center"}>
          Please, enter your phone number/email address used for the
          registration, and we will send you a confirmation code to refresh your
          password.
        </Text>
        <View style={styles.divider} />
        <FormControl.Label>New Password</FormControl.Label>
        <Input
          value={password}
          onChangeText={setPassword}
          type={hidden ? "password" : "text"}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Lock />
            </View>
          }
          InputRightElement={
            <Pressable onPress={showPassword} style={styles.rightIcon}>
              {hidden ? <EyeSlash /> : <Eye />}
            </Pressable>
          }
          placeholder="Enter Password"
        />
        <View style={styles.divider} />
        <FormControl.Label>Confirm Password</FormControl.Label>
        <Input
          value={password2}
          onChangeText={setPassword2}
          type={hidden ? "password" : "text"}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Lock />
            </View>
          }
          InputRightElement={
            <Pressable onPress={showPassword} style={styles.rightIcon}>
              {hidden ? <EyeSlash /> : <Eye />}
            </Pressable>
          }
          placeholder="Confirm Password"
        />
        <View style={styles.buttonContainer}>
          <Button style={styles.button} onPress={confirmPassword}>
            Confirm
          </Button>
        </View>
      </View>
    </Box>
  );
}
