import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G
        stroke="#1DCE80"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round">
        <Path d="M2.5 18.333h15M2.5 1.667h15M10 5v10M12.357 6.433L10 4.075 7.641 6.433M12.357 13.242L10 15.6l-2.358-2.358" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
