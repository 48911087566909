import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G
        stroke="#292D32"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round">
        <Path d="M12.983 10A2.98 2.98 0 0110 12.983 2.98 2.98 0 017.017 10 2.98 2.98 0 0110 7.017 2.98 2.98 0 0112.983 10z" />
        <Path d="M10 16.892c2.941 0 5.683-1.734 7.591-4.734.75-1.175.75-3.15 0-4.325-1.908-3-4.65-4.733-7.591-4.733-2.942 0-5.683 1.733-7.592 4.733-.75 1.175-.75 3.15 0 4.325 1.909 3 4.65 4.734 7.592 4.734z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
