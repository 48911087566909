import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={14}
      height={8}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M7.357 5.936L12.488.713l.48.49-5.965 6.083-5.966-6.084.478-.487 5.13 5.221L7 6.3l.356-.363z"
        fill="#1DCE80"
        stroke="#1DCE80"
      />
    </Svg>
  );
}

export default SvgComponent;
