import axios from "axios";
import { API_URL } from "../config";
import store from "./store";

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(function (config) {
  const token = store.getState()?.auth?.userToken;
  config.headers.Authorization = "Bearer " + token;
  // '.persistor.getState()', (config.headers.Authorization = 'Bearer ' + token);
  return config;
});
export default instance;
