// features/auth/authSlice.js
import {createSlice} from '@reduxjs/toolkit';
import {getProfile, updateProfile} from './profileApi';

const initialState = {
  loading: false,
  fullName: null,
  email: null,
  phone: null,
  address: {
    state: null,
    city: null,
    zip: null,
    county: null,
    streetAddressLine1: null,
    streetAddressLine2: null,
  },
  orders: [],
  updated: false,
};

const profileSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearUpdated(state) {
      state.updated = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      const {fullName, email, phone, address, orders} = action.payload;
      state.fullName = fullName;
      state.email = email;
      state.phone = phone;
      state.address = address;
      state.orders = orders;
      state.loading = false;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const {fullName, email, phone, address, orders} = action.payload;
      state.fullName = fullName;
      state.email = email;
      state.phone = phone;
      state.address = address;
      state.orders = orders;
      state.loading = false;
      state.updated = true;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {clearUpdated} = profileSlice.actions;

export default profileSlice.reducer;
