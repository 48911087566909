import {createSlice} from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    vin: '',
    additionalEquipment: '',
    hasCustom: false,
    havePersonal: false,
    flexibleDates: false,
    images: [],
    // brand: '',
    // model: '',
    // year: '',
    // length: '',
    // height: '',
    // width: '',
    // customChanges: '',
    // personalItems: '',
    // pickUpLocation: '',
    // deliveryLocation: '',
    // pickUpDate1: '',
    // deliveryDate1: '',
    // inoperable: null,
    // trailerType: '',
    // recipientContactName: '',
    // recipientContactPhone: '',
    // recipientContactEmail: '',
  },
  reducers: {
    setInputValue: (state, action) => {
      const {key, value} = action.payload;
      state[key] = value;
    },
    clearInputValues: state => {
      Object.keys(state).forEach(key => {
        state[key] = '';
      });
    },
  },
});

export const {setInputValue, clearInputValues} = orderSlice.actions;
export const selectInputValues = state => state.order;
export default orderSlice.reducer;
