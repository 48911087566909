import { headerHeight } from "../../../config/constants";

const styles = {
  wrapper: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: (window.innerHeight - 489) / 2 - headerHeight,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 40,
    width: 415,
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.22,
    elevation: 5,
  },
  forgotPassword: {
    marginLeft: "auto",
    marginTop: 4,
  },
  button: {
    marginTop: 30,
    marginBottom: 20,
  },
  actionText: {
    fontWeight: "600",
    color: "#1DCE80",
  },
  buttonContainer: {
    marginTop: "auto",
    marginBottom: 30,
  },
  leftIcon: {
    marginLeft: 12,
  },
  root: {
    flex: 1,
    padding: 20,
  },
  codeFieldRoot: {
    marginTop: 20,
  },
  cell: {
    width: 46,
    height: 46,
    lineHeight: 40,
    fontSize: 18,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 12,
    textAlign: "center",
  },
  focusCell: {
    borderColor: "#1DCE80",
    backgroundColor: "#E2FFF2",
  },
};

export default styles;
