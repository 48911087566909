import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  status: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
  statusRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;
