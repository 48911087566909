import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  imagesRow: {
    marginTop: 10,
  },
  image: {
    borderRadius: 8,
    height: 240,
  },
  imageSmall: {
    borderRadius: 8,
    height: 50,
    width: 80,
    marginRight: 8,
  },
  header: {
    color: "#171717",
    fontSize: 20,
    fontWeight: "700",
    marginTop: 20,
  },
  badges: {
    flexDirection: "row",
    marginTop: 10,
  },
  badge: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#DAF8EA",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    borderRadius: 8,
  },
  badgeText: {
    color: "#1DCE80",
    fontSize: 14,
    marginLeft: 8,
  },
  vinBadge: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: "#fff",
    marginTop: 10,
  },
  headerBlock: {
    color: "#171717",
    fontSize: 16,
    fontWeight: "700",
    marginTop: 20,
    marginBottom: 10,
  },
  vinText: {
    marginLeft: 12,
  },
  greyText: {
    fontWeight: "500",
    fontSize: 14,
    color: "#737373",
  },
  locationWrapper: {
    flexDirection: "row",
    marginTop: 12,
  },
  locationTextWrapper: {
    marginLeft: 12,
  },
  locationText: {
    color: "#737373",
    fontSize: 14,
    fontWeight: "500",
  },
  dateRow: {
    flexDirection: "row",
  },
  calendarIcon: {
    marginRight: 8,
  },
  button: {
    marginTop: 24,
    marginBottom: 80,
  },
  row: {
    flexDirection: "row",
  },
  recipientRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 7,
  },
  ml4: {
    marginLeft: 4,
  },
  emptySpace: {
    height: 80,
  },
});

export default styles;
