import * as React from "react";
import {
  AlertDialog,
  Button,
  Center,
  FlatList,
  FormControl,
  Image,
  Pressable,
  Text,
  TextArea,
  View,
  Box,
} from "native-base";
import StarRating from "react-native-star-rating-widget";

import styles from "./styles";

import {
  AddImage,
  Calendar,
  EmptyOrdersImage,
  Line,
  Location,
  Plus,
  Truck2,
  Upload,
} from "../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, profileAddFCM } from "../../networking/profile/profileApi";
import { formatDate } from "../../utils";
import { useState, useEffect } from "react";
import {
  completeOrder,
  disputeOrder,
  rateOrder,
} from "../../networking/orders/ordersApi";
import { useNavigate } from "react-router-dom";

import BidStatus from "../../components/BidStatus";
import { clearInputValues } from "../../networking/orders/ordersSlice";
import ReactImageUploading from "react-images-uploading";
import instance from "../../networking/axios";
import { API_URL } from "../../config";

export default function OrdersListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);
  const [disputeModalIsOpen, setDisputeModalIsOpen] = useState(false);
  const [rateModalIsOpen, setRateModalIsOpen] = useState(false);
  const completeRef = React.useRef(null);
  const disputeRef = React.useRef(null);
  const rateRef = React.useRef(null);
  const [currentOrder, setCurrentOrder] = useState({});

  const { orders } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(clearInputValues());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfile({}));
  }, []);

  const createNewOrder = () => {
    navigate("/create/1");
  };

  const OrderItem = ({ item }) => {
    if (item.deleted) {
      return;
    }
    return (
      <Pressable
        onPress={() => {
          navigate(`/order/${item.id}`);
        }}
        style={styles.orderContainer}
      >
        <View style={styles.truckImage}>
          <Truck2 />
        </View>
        <View style={styles.orderInfo}>
          <Text style={styles.orderName}>
            {item.brand} {item.model} {item.year}
          </Text>
          <BidStatus status={item.status} />
          <View style={styles.orderTextContainer}>
            <Calendar style={styles.icon} />
            <Text style={styles.orderText}>
              {formatDate(new Date(item.pickUpDate1))}
            </Text>
            <Line style={styles.line} />
            <Location style={styles.icon} />
            <Text
              style={styles.orderText}
              numberOfLines={1}
              title={`${item.pickUpLocation} - ${item.deliveryLocation}`}
            >
              {item.pickUpLocation} - {item.deliveryLocation}
            </Text>
          </View>
          <View style={styles.h34}>
            {item.status === "auction" && (
              <Button
                onPress={() => {
                  navigate(`/bids/${item.id}`);
                }}
                style={[styles.completeButton]}
              >
                <Text style={styles.buttonText}>Show Bids</Text>
              </Button>
            )}

            {(item.status === "delivered" || item.status === "dispute") && (
              <View style={styles.buttonsContainer}>
                <Button
                  onPress={() => {
                    setCurrentOrder(item);
                    setCompleteModalIsOpen(true);
                  }}
                  style={[styles.completeButton]}
                >
                  <Text style={styles.buttonText}>Complete Order</Text>
                </Button>
                {item.status === "delivered" && (
                  <Button
                    onPress={async () => {
                      setCurrentOrder(item);
                      setDisputeModalIsOpen(true);
                    }}
                    style={styles.disputeButton}
                  >
                    <Text style={styles.buttonText}>Start dispute</Text>
                  </Button>
                )}
              </View>
            )}
          </View>
        </View>
      </Pressable>
    );
  };

  const CompleteAlert = () => {
    const onClose = () => {
      setCompleteModalIsOpen(false);
    };
    const complete = () => {
      setCompleteModalIsOpen(false);
      dispatch(
        completeOrder({
          data: {
            ...currentOrder,
          },
        })
      ).then(() => {
        dispatch(getProfile({}));
      });
      setRateModalIsOpen(true);
    };
    return (
      <AlertDialog
        leastDestructiveRef={completeRef}
        isOpen={completeModalIsOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Complete Order</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure that you want to mark the order as a completed? After
            that Dispute will be unavailable and we will send the money to the
            carrier
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={completeRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={complete}>
                Complete
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const DisputeAlert = () => {
    const [disputeText, setDisputeText] = useState("");
    const [assets, setAssets] = useState([]);
    const [images, setImages] = useState([]);
    const onClose = () => {
      setDisputeModalIsOpen(false);
    };
    const sendDispute = () => {
      setDisputeModalIsOpen(false);
      dispatch(
        disputeOrder({
          data: {
            id: currentOrder.id,
            disputeText,
            disputeImages: images,
          },
        })
      ).then(() => {
        dispatch(getProfile({}));
      });
    };

    const uploadResource = async ({ file }) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const result = await instance.post(
          `${API_URL}/orders/upload`,
          formData
        );
        if (result?.data?.fileName) {
          setImages((images) => [...images, result?.data?.fileName]);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    };

    const onChange = (imageList, addUpdateIndex) => {
      if (addUpdateIndex) {
        uploadResource(imageList[addUpdateIndex[0]]);
      }
      setAssets(imageList);
    };

    return (
      <AlertDialog
        leastDestructiveRef={disputeRef}
        isOpen={disputeModalIsOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Dispute Request</AlertDialog.Header>
          <AlertDialog.Body>
            <FormControl.Label>Dispute Request</FormControl.Label>
            <TextArea
              // value={disputeText}
              onChangeText={setDisputeText}
              h={20}
              placeholder="Describe the reason for your dispute"
            />
            <ReactImageUploading
              multiple
              value={assets}
              onChange={onChange}
              maxNumber={10}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <div
                    style={styles.dragImages}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <Pressable
                      onPress={onImageUpload}
                      style={styles.addPhotoContainer}
                    >
                      <AddImage />
                      <Text style={styles.addPhotoText}>Add Image</Text>
                    </Pressable>
                  </div>
                  <View style={{ flexDirection: "row" }}>
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{ marginTop: 10, marginRight: 10 }}
                      >
                        <img src={image["data_url"]} alt="" width="100" />
                        <div style={{ textAlign: "center" }}>
                          <button onClick={() => onImageRemove(index)}>
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </View>
                </div>
              )}
            </ReactImageUploading>
            <View style={styles.buttonsContainer}></View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 10,
                marginTop: 15,
              }}
            >
              {assets.map((image, index) => (
                <Pressable onPress={() => {}} key={index}>
                  <Image
                    alt={image.uri}
                    style={styles.image}
                    height={66}
                    width={66}
                    src={image.uri}
                  />
                </Pressable>
              ))}
            </View>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={disputeRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={sendDispute}>
                Send
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const RateAlert = () => {
    const [rating, setRating] = useState(0);
    const onClose = () => {
      setRateModalIsOpen(false);
    };
    const sendRate = () => {
      setRateModalIsOpen(false);
      dispatch(
        rateOrder({
          data: {
            rating,
            ...currentOrder,
          },
        })
      );
    };
    return (
      <AlertDialog
        leastDestructiveRef={rateRef}
        isOpen={rateModalIsOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Feedback</AlertDialog.Header>
          <AlertDialog.Body>
            We'd welcome your feedback! Please rate the carrier and the
            service:" and allows customer to set his lvl of satisfaction from 1
            to 5 stars
            <Center>
              <StarRating mt={20} rating={rating} onChange={setRating} />
            </Center>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={rateRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={sendRate}>
                Submit
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const EmptyComponent = () => {
    return (
      <Box style={styles.wrapper}>
        <View style={styles.emptyContainer}>
          <EmptyOrdersImage />
          <Text style={styles.emptyText}>
            Your order history is empty. Begin by creating your first order and
            discovering the convenience of our services.
          </Text>
        </View>
      </Box>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.subHeaderWrapper}>
        <Text style={styles.myOrdersTitle}>My Orders</Text>
        <Button style={styles.createNewOrder} onPress={createNewOrder}>
          Create Order
        </Button>
      </View>
      <FlatList
        contentContainerStyle={{
          flexDirection: "row",
          flexWrap: "wrap",

          justifyContent: "flex-start",
        }}
        data={
          orders
            ?.filter((order) => !order.deleted)
            ?.sort(function (a, b) {
              return new Date(b.created_at) - new Date(a.created_at);
            }) || []
        }
        renderItem={({ item }) => <OrderItem item={item} />}
        keyExtractor={(item) => item.id}
        refreshing={refreshing}
        onRefresh={() => {
          dispatch(getProfile({}))
            .then(({ payload }) => {
              setRefreshing(false);
            })
            .catch(() => {
              setRefreshing(false);
            });
        }}
        ListEmptyComponent={() => <EmptyComponent />}
        ListFooterComponent={() => <View style={styles.footerBlock} />}
      />

      <CompleteAlert />
      <DisputeAlert />
      <RateAlert />
    </View>
  );
}
