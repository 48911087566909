import * as React from 'react';
import Svg, {G, Rect, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={165}
      height={160}
      viewBox="0 0 165 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G opacity={0.6}>
        <Rect width={157} height={157} rx={78.5} fill="#E8FAF2" />
        <Path
          d="M139.129 136.828L99.695 97.863l-7.808 7.902 39.433 38.965 7.809-7.902z"
          fill="#1AB973"
        />
        <Path
          d="M154.192 147.298l-30.68-30.315a.433.433 0 00-.614.003l-11.612 11.752a.435.435 0 00.003.615l30.68 30.315c.17.169.446.167.615-.004l11.611-11.751a.435.435 0 00-.003-.615zM64.746 117.459c27.13 0 49.122-21.992 49.122-49.121 0-27.13-21.993-49.122-49.122-49.122S15.625 41.21 15.625 68.338s21.992 49.121 49.121 49.121z"
          fill="#1DCE80"
        />
        <Path
          d="M64.747 109.444c22.702 0 41.106-18.404 41.106-41.106 0-22.703-18.404-41.107-41.106-41.107S23.641 45.635 23.641 68.338c0 22.702 18.404 41.106 41.106 41.106z"
          fill="#B9F0D8"
        />
        <Path
          d="M143.976 14.16v17.004a3.79 3.79 0 01-3.785 3.785h-18.723a2.052 2.052 0 01-2.046-2.046V19.828a7.545 7.545 0 017.55-7.55h15.101c1.064 0 1.903.838 1.903 1.882z"
          fill="#1DCE80"
        />
        <Path
          d="M159.323 39.901c.573 0 1.023.45 1.023 1.023v2.046a6.13 6.13 0 01-6.139 6.139c0-3.376-2.762-6.139-6.138-6.139s-6.139 2.763-6.139 6.139h-4.092c0-3.376-2.763-6.139-6.139-6.139-3.376 0-6.138 2.763-6.138 6.139a6.13 6.13 0 01-6.139-6.139v-4.092c0-1.126.921-2.046 2.046-2.046h19.439a5.118 5.118 0 005.116-5.116V20.462c0-1.125.92-2.046 2.046-2.046h1.719c1.473 0 2.823.798 3.56 2.066l1.31 2.292a.516.516 0 01-.451.757 5.117 5.117 0 00-5.115 5.116v6.138a5.117 5.117 0 005.115 5.116h5.116z"
          fill="#1DCE80"
        />
        <Path
          d="M131.694 53.201a4.092 4.092 0 100-8.184 4.092 4.092 0 000 8.184zM148.069 53.201a4.092 4.092 0 100-8.184 4.092 4.092 0 000 8.184zM160.341 33.824v3.008h-6.139a2.052 2.052 0 01-2.046-2.047v-6.138c0-1.126.921-2.046 2.046-2.046h2.64l2.967 5.197a4.1 4.1 0 01.532 2.026zM40.72 127.787v10.037a2.237 2.237 0 01-2.234 2.234H27.434a1.21 1.21 0 01-1.207-1.207v-7.718a4.454 4.454 0 014.456-4.457h8.914a1.11 1.11 0 011.123 1.111z"
          fill="#1DCE80"
        />
        <Path
          d="M49.778 142.981c.338 0 .604.266.604.604v1.208a3.618 3.618 0 01-3.623 3.623 3.634 3.634 0 00-3.624-3.623 3.634 3.634 0 00-3.623 3.623h-2.416a3.634 3.634 0 00-3.623-3.623 3.634 3.634 0 00-3.623 3.623 3.618 3.618 0 01-3.623-3.623v-2.416a1.21 1.21 0 011.207-1.207h11.474a3.02 3.02 0 003.02-3.02v-6.643a1.21 1.21 0 011.207-1.207h1.015c.87 0 1.667.471 2.102 1.219l.773 1.353a.305.305 0 01-.266.447 3.02 3.02 0 00-3.02 3.019v3.624a3.02 3.02 0 003.02 3.019h3.02z"
          fill="#1DCE80"
        />
        <Path
          d="M33.478 150.832a2.416 2.416 0 100-4.832 2.416 2.416 0 000 4.832zM43.134 150.832a2.416 2.416 0 100-4.832 2.416 2.416 0 000 4.832zM50.386 139.394v1.775h-3.623a1.21 1.21 0 01-1.208-1.207v-3.624a1.21 1.21 0 011.208-1.207h1.558l1.75 3.067c.206.363.315.773.315 1.196z"
          fill="#1DCE80"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
