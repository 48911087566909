import { headerHeight } from "../../../config/constants";

const styles = {
  wrapper: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: (window.innerHeight - 319) / 2 - headerHeight,
  },
  container: {
    padding: 40,
    flex: 1,
    justifyContent: "center",
    width: 415,
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.22,
    elevation: 5,
  },
  actionText: {
    fontWeight: "600",
    color: "#1DCE80",
  },
  buttonContainer: {
    // marginTop: 'auto',
    marginTop: 40,
    marginBottom: 20,
  },

  root: {
    flex: 1,
    padding: 20,
  },
  codeFieldRoot: {
    marginTop: 20,
  },
  cell: {
    width: 46,
    height: 46,
    lineHeight: 40,
    fontSize: 18,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 12,
    textAlign: "center",
  },
  focusCell: {
    borderColor: "#1DCE80",
  },
  inputOpt: {
    fontSize: 16,
    height: 46,
    width: 46,
    border: "1px solid #E5E5E5",
    borderRadius: 12,
    appearance: "none",
  },
};

export default styles;
