import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect width={40} height={40} rx={12} fill="#1DCE80" />
      <Path
        d="M27.474 10.833h-2.45c-.725 0-1.259.3-1.5.834-.134.241-.192.525-.192.858v2.45c0 1.058.633 1.692 1.692 1.692h2.45c.333 0 .616-.059.858-.192.533-.242.833-.775.833-1.5v-2.45c0-1.058-.633-1.692-1.691-1.692zm.783 3.275a.54.54 0 01-.342.15H26.74v.425l.009.734a.527.527 0 01-.159.358.492.492 0 01-.341.142.501.501 0 01-.5-.5V14.25l-1.167.008a.509.509 0 01-.5-.508c0-.275.225-.5.5-.5l.733.008h.434v-1.166c0-.275.225-.509.5-.509s.5.234.5.509l-.009.591v.567h1.175c.275 0 .5.225.5.5a.567.567 0 01-.158.358zM17.499 18.65a1.983 1.983 0 100-3.967 1.983 1.983 0 000 3.967z"
        fill="#fff"
      />
      <Path
        d="M27.476 16.667h-.391v3.841l-.109-.091c-.65-.559-1.7-.559-2.35 0l-3.466 2.975c-.65.558-1.7.558-2.35 0l-.284-.234c-.591-.516-1.533-.566-2.2-.116l-3.116 2.091a4.445 4.445 0 01-.292-1.641v-6.984c0-2.35 1.242-3.591 3.592-3.591h6.825v-.392c0-.333.058-.617.191-.858H16.51c-3.034 0-4.842 1.808-4.842 4.841v6.984c0 .908.158 1.7.467 2.366.716 1.584 2.25 2.475 4.375 2.475h6.983c3.033 0 4.842-1.808 4.842-4.841v-7.017c-.242.133-.525.192-.859.192z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
