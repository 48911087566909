import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M1.908 6.483v8.109c0 1.583 1.125 2.233 2.492 1.45l1.958-1.117c.425-.242 1.134-.267 1.575-.042l4.375 2.192c.442.217 1.15.2 1.575-.042l3.608-2.066c.459-.267.842-.917.842-1.45V5.408c0-1.583-1.125-2.233-2.491-1.45l-1.959 1.117c-.425.242-1.133.267-1.575.042L7.933 2.933c-.441-.216-1.15-.2-1.575.042L2.75 5.042c-.467.266-.842.916-.842 1.441zM7.133 3.333v10.834M13.107 5.517v11.15"
        stroke="#1DCE80"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
