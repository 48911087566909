import * as React from 'react';
import Svg, {Mask, Path, G} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Mask id="a" fill="#fff">
        <Path d="M1.043 17.5v-15a.63.63 0 01.625-.625.63.63 0 01.625.625v15a.63.63 0 01-.625.625.63.63 0 01-.625-.625z" />
      </Mask>
      <Path
        d="M2.543 17.5v-15h-3v15h3zm0-15a.87.87 0 01-.875.875v-3A2.13 2.13 0 00-.457 2.5h3zm-.875.875A.87.87 0 01.793 2.5h3A2.13 2.13 0 001.668.375v3zM.793 2.5v15h3v-15h-3zm0 15a.87.87 0 01.875-.875v3A2.13 2.13 0 003.793 17.5h-3zm.875-.875a.87.87 0 01.875.875h-3a2.13 2.13 0 002.125 2.125v-3z"
        fill="#1DCE80"
        mask="url(#a)"
      />
      <Mask id="b" fill="#fff">
        <Path d="M17.707 17.5v-15a.63.63 0 01.625-.625.63.63 0 01.625.625v15a.63.63 0 01-.625.625.63.63 0 01-.625-.625z" />
      </Mask>
      <Path
        d="M17.707 17.5v-15a.63.63 0 01.625-.625.63.63 0 01.625.625v15a.63.63 0 01-.625.625.63.63 0 01-.625-.625z"
        fill="#292D32"
      />
      <Path
        d="M19.207 17.5v-15h-3v15h3zm0-15a.87.87 0 01-.875.875v-3A2.13 2.13 0 0016.207 2.5h3zm-.875.875a.87.87 0 01-.875-.875h3A2.13 2.13 0 0018.332.375v3zm-.875-.875v15h3v-15h-3zm0 15a.87.87 0 01.875-.875v3a2.13 2.13 0 002.125-2.125h-3zm.875-.875a.87.87 0 01.875.875h-3a2.13 2.13 0 002.125 2.125v-3z"
        fill="#1DCE80"
        mask="url(#b)"
      />
      <G>
        <Mask id="c" fill="#fff">
          <Path d="M7.201 11.917L5.91 10.625h8.508l-1.292 1.292a.618.618 0 00-.183.441c0 .159.058.317.183.442a.629.629 0 00.884 0l2.358-2.358a.645.645 0 000-.884L14.01 7.2a.629.629 0 00-.884 0 .629.629 0 000 .883l1.292 1.292H5.91L7.2 8.083a.629.629 0 000-.883.629.629 0 00-.883 0L3.96 9.558a.624.624 0 000 .884L6.318 12.8a.629.629 0 00.883 0 .629.629 0 000-.883z" />
        </Mask>
        <Path
          d="M7.201 11.917L5.91 10.625h8.508l-1.292 1.292a.618.618 0 00-.183.441c0 .159.058.317.183.442a.629.629 0 00.884 0l2.358-2.358a.645.645 0 000-.884L14.01 7.2a.629.629 0 00-.884 0 .629.629 0 000 .883l1.292 1.292H5.91L7.2 8.083a.629.629 0 000-.883.629.629 0 00-.883 0L3.96 9.558a.624.624 0 000 .884L6.318 12.8a.629.629 0 00.883 0 .629.629 0 000-.883z"
          fill="#292D32"
        />
        <Path
          d="M5.91 10.625v-1.5H2.288l2.561 2.56 1.06-1.06zm8.508 0l1.06 1.06 2.561-2.56h-3.621v1.5zM14.01 12.8l-1.061-1.06 1.06 1.06zm2.358-2.358l1.06 1.06-1.06-1.06zm0-.884l1.06-1.06-1.06 1.06zM14.01 7.2l-1.061 1.06 1.06-1.06zm.408 2.175v1.5h3.621l-2.56-2.56-1.061 1.06zm-8.508 0l-1.061-1.06-2.56 2.56h3.62v-1.5zM6.318 7.2l1.06 1.06-1.06-1.06zM3.96 9.558l-1.061-1.06 1.06 1.06zm4.302 1.298L6.97 9.564 4.85 11.686l1.292 1.291 2.121-2.12zM5.91 12.125h8.508v-3H5.91v3zm7.447-2.56l-1.291 1.291 2.121 2.121 1.292-1.291-2.122-2.122zm-1.291 1.291a2.118 2.118 0 00-.623 1.502h3a.882.882 0 01-.256.62l-2.121-2.122zm-.623 1.502c0 .535.202 1.082.623 1.503l2.121-2.122a.882.882 0 01.256.62h-3zm.623 1.503a2.129 2.129 0 003.004 0l-2.121-2.122a.871.871 0 011.238 0l-2.121 2.122zm3.004 0l2.359-2.359-2.122-2.121-2.358 2.358 2.121 2.122zm2.359-2.359c.819-.819.819-2.185 0-3.004l-2.122 2.121a.877.877 0 01-.264-.619c0-.181.064-.418.264-.619l2.122 2.121zm0-3.004L15.07 6.139 12.95 8.261l2.358 2.358 2.122-2.121zM15.07 6.139a2.129 2.129 0 00-3.004 0l2.121 2.122a.871.871 0 01-1.238 0l2.121-2.122zm-3.004 0a2.129 2.129 0 000 3.005l2.121-2.121a.871.871 0 010 1.238l-2.121-2.122zm0 3.005l1.291 1.292 2.122-2.122-1.292-1.291-2.121 2.121zm2.352-1.269H5.91v3h8.508v-3zm-7.448 2.56l1.292-1.291-2.121-2.121-1.292 1.291 2.121 2.122zm1.292-1.291a2.129 2.129 0 000-3.005L6.141 8.261a.871.871 0 010-1.238l2.121 2.121zm0-3.005a2.129 2.129 0 00-3.005 0L7.38 8.261a.871.871 0 01-1.238 0l2.121-2.122zm-3.005 0L2.9 8.498l2.121 2.121L7.38 8.261 5.257 6.139zM2.9 8.498A2.124 2.124 0 002.276 10h3a.876.876 0 01-.256.619L2.9 8.498zM2.276 10c0 .57.228 1.108.623 1.502L5.02 9.381a.876.876 0 01.256.619h-3zm.623 1.502l2.358 2.359 2.122-2.122L5.02 9.381 2.9 11.502zm2.358 2.359a2.129 2.129 0 003.005 0l-2.121-2.122a.871.871 0 011.238 0l-2.122 2.122zm3.005 0a2.129 2.129 0 000-3.005l-2.121 2.121a.871.871 0 010-1.238l2.121 2.122z"
          fill="#1DCE80"
          mask="url(#c)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
