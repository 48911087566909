import * as React from "react";
import {
  Button,
  ScrollView,
  Text,
  View,
  Image,
  AlertDialog,
  Pressable,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import {
  CalendarGrey,
  GeoGreen,
  GreenEmail,
  GreenPhone,
  GreenUser,
  Height,
  Length,
  LocationGreen,
  TrailerClosed,
  TrailerOpen,
  VinGreen,
  Weight,
  Width,
} from "../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { formatStringToDate } from "../../utils";
import { imageHost } from "../../config";
import { deleteOrder } from "../../networking/orders/ordersApi";
import BidStatus from "../../components/BidStatus";
import { useNavigate, useParams } from "react-router-dom";

export default function OrderScreen({ match }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const orders = useSelector((state) => state.profile.orders);
  const order = orders.find((order) => order.id === id);
  const dispatch = useDispatch();
  const [shownDeleteModal, setShownDeleteModal] = useState(false);
  const deleteRef = React.useRef(null);

  if (!order) {
    return <View />;
  }
  const {
    vin,
    brand,
    model,
    year,
    length,
    height,
    width,
    additionalEquipment,
    customChanges,
    personalItems,
    pickUpLocation,
    deliveryLocation,
    pickUpDate1,
    deliveryDate1,
    images,
    status,
    inoperable,
    trailerType,
    recipientContactName,
    recipientContactEmail,
    recipientContactPhone,
    weight,
  } = order;

  const [mainImage, setMainImage] = useState(images?.length ? images[0] : null);

  const DeleteAlert = () => {
    const onClose = () => {
      setShownDeleteModal(false);
    };
    const onDelete = () => {
      setShownDeleteModal(false);
      dispatch(deleteOrder({ id })).then(() => {
        navigate("/");
      });
    };

    return (
      <AlertDialog
        leastDestructiveRef={deleteRef}
        isOpen={shownDeleteModal}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Delete Order</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to delete the order?
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={deleteRef}
              >
                Cancel
              </Button>
              <Button style={styles.dcButton} onPress={onDelete}>
                Delete
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  useEffect(() => {}, [id]);
  return (
    <ScrollView style={styles.container}>
      <View style={styles.status}>
        <Text>Status: </Text>
        <BidStatus status={status} />
      </View>
      {!!images?.length && (
        <Image style={styles.image} height={200} src={imageHost + mainImage} />
      )}
      <ScrollView horizontal style={styles.imagesRow}>
        {images?.map((image, index) => (
          <Pressable onPress={() => setMainImage(image)} key={index}>
            <Image style={styles.imageSmall} src={imageHost + image} />
          </Pressable>
        ))}
      </ScrollView>
      <Text style={styles.header}>
        {brand} {model}
      </Text>
      <Text style={styles.subheader}>Year {year}</Text>
      <View style={styles.badges}>
        <View mr={"4px"} ml={"4px"} style={styles.badge}>
          <Weight />
          <Text style={styles.badgeText}>{weight}</Text>
        </View>
        <View mr={"4px"} style={styles.badge}>
          <Length />
          <Text style={styles.badgeText}>{length}</Text>
        </View>
        <View mr={"4px"} ml={"4px"} style={styles.badge}>
          <Height />
          <Text style={styles.badgeText}>{height}</Text>
        </View>
        <View ml={"4px"} style={styles.badge}>
          <Width />
          <Text style={styles.badgeText}>{width}</Text>
        </View>
      </View>
      <View shadow="3" style={styles.vinBadge}>
        <VinGreen />
        <View style={styles.vinText}>
          <Text style={styles.greyText}>VIN code</Text>
          <Text>{vin}</Text>
        </View>
      </View>
      {!!additionalEquipment?.length && (
        <>
          <Text style={styles.headerBlock}>Additional equipment</Text>
          <Text>{additionalEquipment}</Text>
        </>
      )}
      <>
        <Text style={styles.headerBlock}>Is car inoperable?</Text>
        <Text>{inoperable ? "Yes" : "No"}</Text>
      </>
      <>
        <Text style={styles.headerBlock}>Trailer Type</Text>
        {trailerType === "closed" && (
          <View style={styles.row}>
            <TrailerClosed />
            <Text style={styles.ml4} fontSize="sm">
              Enclosed Trailer
            </Text>
          </View>
        )}
        {trailerType === "open" && (
          <View style={styles.row}>
            <TrailerOpen />
            <Text style={styles.ml4} fontSize="sm">
              Open Trailer
            </Text>
          </View>
        )}
      </>
      {!!customChanges?.length && (
        <>
          <Text style={styles.headerBlock}>Car has custom changes/tuning</Text>
          <Text>{customChanges}</Text>
        </>
      )}
      {!!personalItems?.length && (
        <>
          <Text style={styles.headerBlock}>Personal items</Text>
          <Text>{personalItems}</Text>
        </>
      )}
      <View style={styles.locationWrapper}>
        <LocationGreen />
        <View style={styles.locationTextWrapper}>
          <Text style={styles.locationText}>Pick Up Location</Text>
          <Text>{pickUpLocation}</Text>
          <View style={styles.dateRow}>
            <View style={styles.calendarIcon}>
              <CalendarGrey />
            </View>

            <Text>{formatStringToDate(pickUpDate1)}</Text>
          </View>
        </View>
      </View>
      <View style={styles.locationWrapper}>
        <GeoGreen />
        <View style={styles.locationTextWrapper}>
          <Text style={styles.locationText}>Delivery Location</Text>
          <Text>{deliveryLocation}</Text>
          <View style={styles.dateRow}>
            <View style={styles.calendarIcon}>
              <CalendarGrey />
            </View>
            <Text>{formatStringToDate(deliveryDate1)}</Text>
          </View>
        </View>
      </View>
      <>
        <Text style={styles.headerBlock}>Recipient</Text>
        <View style={styles.recipientRow}>
          <GreenUser />
          <Text style={styles.locationTextWrapper}>{recipientContactName}</Text>
        </View>
        <View style={styles.recipientRow}>
          <GreenEmail />
          <Text style={styles.locationTextWrapper}>
            {recipientContactEmail}
          </Text>
        </View>
        <View style={styles.recipientRow}>
          <GreenPhone />
          <Text style={styles.locationTextWrapper}>
            {recipientContactPhone}
          </Text>
        </View>
      </>
      {status === "auction" && (
        <View style={styles.blockWrapper}>
          <Text style={styles.header}>Bids</Text>
          <Button
            style={styles.button}
            onPress={() => {
              navigate(`/bids/${id}`);
            }}
          >
            View Bids
          </Button>
          {/*<BidsScreen showTitle={false} />*/}
        </View>
      )}
      {status === "auction" && (
        <View style={styles.blockWrapper}>
          <Text style={styles.header}>Delete Order</Text>
          <Button
            style={styles.deleteButton}
            onPress={() => {
              setShownDeleteModal(true);
            }}
          >
            Delete Order
          </Button>
        </View>
      )}
      <DeleteAlert />
      <View style={styles.divider} />
    </ScrollView>
  );
}
