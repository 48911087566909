import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G stroke="#737373" strokeWidth={1.3}>
        <Path d="M8.002 8.953a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16z" />
        <Path d="M2.412 5.66c1.313-5.773 9.867-5.767 11.173.007.767 3.386-1.34 6.253-3.187 8.026a3.462 3.462 0 01-4.806 0c-1.84-1.773-3.947-4.646-3.18-8.033z" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
